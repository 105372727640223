import { useDispatch } from 'react-redux'
import styles from './RecentProjects.module.css'
import { useRef, useEffect, useState } from 'react'
import { setProjects } from '../../store/sections/sectionsAction'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

export default function RecentProjects() {
  const [webpSupported, setWebpSupported] = useState(null)
  const dispatch = useDispatch()
  const recentProjects = useRef(null)
  const [products, setProducts] = useState([])
  const lang = useSelector((state) => state.lang.lang.toLowerCase())

  // useEffect(() => {
  //   setTimeout(() => {
  //     const projectsValue = recentProjects.current.offsetTop

  //     localStorage.setItem('projects', projectsValue)
  //   }, 1000)
  // }, [recentProjects?.current?.offsetTop])

  const fetchProducts = async () => {
    try {
      const res = await axios.get(
        'https://elwoodnelinka-4b15b25292df.herokuapp.com/recentProjects'
      )
      const langLowerCase = lang.toLowerCase()

      const productsWithParsedJSON =
        res.data &&
        res.data
          .map((item) => {
            const images = item[`images_${langLowerCase}`]
              ? JSON.parse(item[`images_${langLowerCase}`])
              : []

            const properties = item[`properties_${langLowerCase}`]
              ? JSON.parse(item[`properties_${langLowerCase}`])
              : []

            const title = item[`title_${langLowerCase}`]
              ? item[`title_${langLowerCase}`]
              : ''

            return {
              ...item,
              images,
              properties,
              title,
            }
          })
          .filter((item) => +item.disabled === 0)
          .filter((item) => +item.status === 1)
          .slice(0, 3)
      productsWithParsedJSON && setProducts(productsWithParsedJSON)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchProducts()
  }, [])

  useEffect(() => {
    fetchProducts()
  }, [lang])

  const { t, i18n } = useTranslation()

  useEffect(() => {
    if (recentProjects.current) {
      dispatch(setProjects(recentProjects))
    }
  }, [dispatch])

  useEffect(() => {
    const canvas = document.createElement('canvas')
    if (canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0) {
      setWebpSupported(true)
    } else {
      setWebpSupported(false)
    }
  }, [])

  return (
    <>
      <h1 ref={recentProjects} className={styles.mainTitle}>
        {t('recentProjects')}
      </h1>
      <div initial="hidden" whileInView="visible">
        <section className={styles.section}>
          <div className={styles.wrap}>
            {webpSupported === false ||
              (webpSupported === true && products.length > 0 && (
                <div className={styles.container}>
                  <div className={styles.left}>
                    <Link to={`/recentProjects/project/${products[0]?.id}`}>
                      <div className={styles.imageContainer}>
                        <img
                          src={
                            products[0]?.images &&
                            Array.isArray(products[0]?.images) &&
                            webpSupported
                              ? products[0]?.images[0].webp
                              : products[0]?.images[0].jpeg
                          }
                          alt="Recent Image"
                          className={styles.mainRecent}
                        />
                        <div className={styles.textContainer}>
                          <h3 className={styles.leftImgTitle}>
                            {products[0]?.title}
                          </h3>
                          <p className={styles.data}>{products[0]?.date}</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className={styles.right}>
                    <div className={styles.recentProject}>
                      <Link to={`/recentProjects/project/${products[1]?.id}`}>
                        <div className={styles.imageWrapper}>
                          <img
                            src={
                              products[1]?.images &&
                              Array.isArray(products[0]?.images) &&
                              webpSupported
                                ? products[1]?.images[0].webp
                                : products[1]?.images[0].jpeg
                            }
                            alt="Mini Recent"
                            className={styles.recentImg}
                          />
                          <div
                            className={`${styles.textContainer} ${styles.miniTextContainer}`}>
                            <h2
                              className={`${styles.leftImgTitle} ${styles.miniRecentTitle}`}>
                              {products[1]?.title}
                            </h2>
                            <p className={`${styles.data} ${styles.miniData}`}>
                              {products[1]?.date}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className={styles.recentProject}>
                      <Link to={`/recentProjects/project/${products[2]?.id}`}>
                        {' '}
                        <div className={styles.imageWrapper}>
                          <img
                            src={
                              products[2]?.images &&
                              Array.isArray(products[0]?.images) &&
                              webpSupported
                                ? products[2]?.images[0].webp
                                : products[2]?.images[0].jpeg
                            }
                            alt="Mini Recent"
                            className={styles.recentImg}
                          />
                          <div
                            className={`${styles.textContainer} ${styles.miniTextContainer}`}>
                            <h2
                              className={`${styles.leftImgTitle} ${styles.miniRecentTitle}`}>
                              {products[2]?.title}
                            </h2>
                            <p className={`${styles.data} ${styles.miniData}`}>
                              {products[2]?.date}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            <div className={styles.btnContainer}>
              <Link to="/recentsProjects" className={styles.seeAllBtn}>
                {t('seeAll')}
              </Link>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
