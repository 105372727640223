import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import styles from './Slider.module.css'
import { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios'
import storage from '../../firebase'
import { ref, getDownloadURL, listAll } from 'firebase/storage'
import { useNavigate } from 'react-router-dom'

function MainSlider() {
  const navigate = useNavigate()
  const [imageIndex, setImageIndex] = useState(0)
  const [baners, setBaners] = useState([])
  const [imageLink, setImgLink] = useState('')
  const [webpSupported, setWebpSupported] = useState(false)

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const res = await axios.get(
          'https://elwoodnelinka-4b15b25292df.herokuapp.com/baners'
        )
        const datasParser =
          res.data &&
          res.data.map((item) => {
            return {
              id: item.id,
              img: JSON.parse(item.img),
            }
          })

        setBaners(datasParser)
      } catch (error) {
        console.log(error)
      }
    }
    fetchBanners()
  }, [])

  function handleNavigateAllProducts() {
    navigate('/allProducts')
  }

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    lazyLoad: true,
    speed: 500,
    slidesToShow:
      imageLink.length <= 2 ? 1 : imageLink.length > 2 ? 2 : imageLink.length,

    slidesToScroll: 1,
    centerMode: imageLink.length <= 2 && true,
    centerPadding: imageLink.length <= 2 && ' 160px 0 0 0',
    beforeChange: (current, next) => setImageIndex(next),
    swipe: true,
    touchMove: true,
    touchThreshold: 15,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  useEffect(() => {
    const canvas = document.createElement('canvas')
    if (canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0) {
      setWebpSupported(true)
    } else {
      setWebpSupported(false)
    }
  }, [])

  return (
    <div className={styles.sliderWrapper}>
      <Slider {...settings}>
        {baners.length > 0 &&
          baners?.map((slider, idx) => (
            <div
              key={new Date()}
              className={
                idx === imageIndex
                  ? `${styles.slide} ${styles.activeSlide}`
                  : styles.slide
              }>
              <img
                onDoubleClick={handleNavigateAllProducts}
                src={webpSupported ? slider?.img?.webp : slider?.img?.jpeg}
                alt={`Baner No ${idx + 1}`}
                className={styles.img}
              />
            </div>
          ))}
      </Slider>
    </div>
  )
}

export default MainSlider
