import styles from './AdminAbout.module.css'
import axios from 'axios'
import Button from '@mui/material/Button'
import Description from '../ProductPage/Description/Description'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../components/Loader/Loader'
import { Modal as AntDModal } from 'antd'
import storage from '../../firebase'
import { ref, deleteObject } from 'firebase/storage'

export default function AdminAbout() {
  const navigate = useNavigate()
  const [am, setAm] = useState(null)
  const [ru, setRu] = useState(null)
  const [en, setEn] = useState(null)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [itemId, setItemId] = useState(null)
  const [webpSupported, setWebpSupported] = useState(false)
  const [loader, setLoading] = useState(true)

  const showModal = () => {
    setDeleteOpen(true)
  }

  const hideModal = () => {
    setDeleteOpen(false)
  }

  useEffect(() => {
    setLoading(true)
    const fetchAboutAm = async () => {
      try {
        const res = await axios.get(
          'https://elwoodnelinka-4b15b25292df.herokuapp.com/about'
        )
        const fileredAbout =
          res.data &&
          res.data
            .filter((item) => item.lang === 'arm')
            .map((item) => {
              const img = JSON.parse(item?.img)

              return {
                img,
                description: item?.description,
                lang: item?.lang,
                id: item?.id,
              }
            })
        setLoading(false)
        fileredAbout?.length > 0 && setAm(fileredAbout)
      } catch (error) {
        toast.error(error.message)
        setLoading(false)
      }
    }
    fetchAboutAm()
  }, [])

  async function handleDelete(item) {
    if (item.img) {
      const imageRefJpeg = ref(storage, item.img.jpeg)
      const imageRefWebp = ref(storage, item.img.webp)

      deleteObject(imageRefJpeg).then(() =>
        console.log('Jpeg has been delete sucess')
      )
      deleteObject(imageRefWebp).then(() =>
        console.log('Webp has been elete success')
      )
    }

    try {
      await axios
        .delete(
          `https://elwoodnelinka-4b15b25292df.herokuapp.com/deleteabout/${item.itemId}`
        )
        .then(() => window.location.reload())
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setLoading(true)
    const fetchAboutRu = async () => {
      try {
        const res = await axios.get(
          'https://elwoodnelinka-4b15b25292df.herokuapp.com/about'
        )
        const fileredAbout =
          res.data &&
          res.data
            .filter((item) => item.lang === 'rus')
            .map((item) => {
              const img = JSON.parse(item?.img)

              return {
                img,
                description: item?.description,
                lang: item?.lang,
                id: item?.id,
              }
            })

        fileredAbout.length > 0 && setRu(fileredAbout)
        fileredAbout && console.log(true)
        setLoading(false)
      } catch (error) {
        toast.error(error.message)
        setLoading(false)
      }
    }
    fetchAboutRu()
  }, [])

  useEffect(() => {
    setLoading(true)
    const fetchAboutEn = async () => {
      try {
        const res = await axios.get(
          'https://elwoodnelinka-4b15b25292df.herokuapp.com/about'
        )
        const fileredAbout =
          res.data &&
          res.data
            .filter((item) => item.lang === 'eng')
            .map((item) => {
              const img = JSON.parse(item?.img)

              return {
                img,
                description: item?.description,
                lang: item?.lang,
                id: item?.id,
              }
            })
        setLoading(false)
        fileredAbout?.length > 0 && setEn(fileredAbout)
      } catch (error) {
        toast.error(error.message)
        setLoading(false)
      }
    }
    fetchAboutEn()
  }, [])

  useEffect(() => {
    const canvas = document.createElement('canvas')
    if (canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0) {
      setWebpSupported(true)
    } else {
      setWebpSupported(false)
    }
  }, [])

  return (
    <div className={styles.container}>
      <h2 style={{ marginBottom: '100px' }}>Home Page Section 3</h2>

      <div className={styles.langContainer}>
        <h2 className={styles.title}>Հայերեն</h2>
        {am !== null ? (
          <div className={styles.imgContainer}>
            {am?.map((item) => (
              <div key={item.id} className={styles.abCont}>
                <img
                  src={webpSupported ? item?.img?.webp : item?.img?.jpeg}
                  alt="About Us img"
                  className={styles.img}
                />
                <div className={styles.description}>
                  <Description desc={item.description} />
                </div>
                <div className={styles.actionContainer}>
                  <div
                    onClick={() => {
                      setItemId({ itemId: item.id, img: item.img })
                      showModal()
                    }}
                    className={`${styles.button} ${styles.button2}`}>
                    Delete
                  </div>
                  <Link to={`/admin/admindashboard/about/editAbout/${item.id}`}>
                    {' '}
                    <div className={`${styles.button} ${styles.button3}`}>
                      Edit
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <Button
            onClick={() =>
              navigate('/admin/admindashboard/aboutus/addaboutpage/am')
            }
            variant="contained"
            disableElevation>
            Ավելացնել մեր մասին էջը Հայերեն տարբերակով
          </Button>
        )}
      </div>
      <div className={styles.langContainer}>
        <h2 className={styles.title}>Русский</h2>
        {ru !== null ? (
          <div className={styles.imgContainer}>
            {ru?.map((item) => (
              <div key={item.id} className={styles.abCont}>
                <img
                  src={webpSupported ? item?.img?.webp : item?.img?.jpeg}
                  alt="About Us img"
                  className={styles.img}
                />
                <div className={styles.description}>
                  <Description desc={item.description} />
                </div>
                <div className={styles.actionContainer}>
                  <div
                    onClick={() => {
                      setItemId({ itemId: item.id, img: item.img })
                      showModal()
                    }}
                    className={`${styles.button} ${styles.button2}`}>
                    Delete
                  </div>
                  <Link to={`/admin/admindashboard/about/editAbout/${item.id}`}>
                    {' '}
                    <div className={`${styles.button} ${styles.button3}`}>
                      Edit
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <Button
            onClick={() =>
              navigate('/admin/admindashboard/aboutus/addaboutpage/ru')
            }
            variant="contained"
            disableElevation>
            Добавить о нас страницу для русского варианта
          </Button>
        )}
      </div>
      <div className={styles.langContainer}>
        <h2 className={styles.title}>English</h2>
        {en !== null ? (
          <div className={styles.imgContainer}>
            {en?.map((item) => (
              <div key={item.id} className={styles.abCont}>
                <img
                  src={webpSupported ? item?.img?.webp : item?.img?.jpeg}
                  alt="About Us img"
                  className={styles.img}
                />
                <div className={styles.description}>
                  <Description desc={item.description} />
                </div>
                <div className={styles.actionContainer}>
                  <div
                    onClick={() => {
                      setItemId({ itemId: item.id, img: item.img })
                      showModal()
                    }}
                    className={`${styles.button} ${styles.button2}`}>
                    Delete
                  </div>
                  <Link to={`/admin/admindashboard/about/editAbout/${item.id}`}>
                    {' '}
                    <div className={`${styles.button} ${styles.button3}`}>
                      Edit
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <Button
            onClick={() =>
              navigate('/admin/admindashboard/aboutus/addaboutpage/en')
            }
            variant="contained"
            disableElevation>
            Add about us page with English
          </Button>
        )}
      </div>
      <AntDModal
        title="You are sure delete category"
        open={deleteOpen}
        onOk={() => {
          handleDelete(itemId)
          hideModal()
        }}
        onCancel={hideModal}
        okText="Delete"
        cancelText="Cancel"></AntDModal>
      <ToastContainer />
      {loader && <Loader />}
    </div>
  )
}
