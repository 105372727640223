import DataTable from 'react-data-table-component'
import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import Home from '@mui/icons-material/Home'
import NewspaperIcon from '@mui/icons-material/Newspaper'
import StarIcon from '@mui/icons-material/Star'
import styles from './ProductsTable.module.css'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import axios from 'axios'
import Switch from '@mui/material/Switch'
import { styled } from '@mui/system'
import DeleteIcon from '@mui/icons-material/Delete'
import CreateIcon from '@mui/icons-material/Create'
import NewReleasesIcon from '@mui/icons-material/NewReleases'
import Loader from '../../Loader/Loader'
import { Modal as AntDModal } from 'antd'
import { ref, deleteObject } from 'firebase/storage'
import storage from '../../../firebase'

export default function ProductsTable() {
  const [preloader, setPreLoader] = useState(true)
  const [test, setTest] = useState([])
  const [products, setProducts] = useState([])
  const [webpSupported, setWebpSupported] = useState(false)
  const [filteredProducts, setFilteredProducts] = useState([])
  const [search, setSearch] = useState('')
  const [isChecked, setIsChecked] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [itemId, setItemId] = useState(null)
  const [status, setStatus] = useState('0')
  const [homeStatus, setHomeStatus] = useState(false)
  const [saleItemsStatus, setSaleItemsStatus] = useState(false)
  const StyledIconButton = styled(IconButton)({
    '&:hover': {
      color: 'red',
    },
  })

  const showModal = () => {
    setDeleteOpen(true)
  }

  const hideModal = () => {
    setDeleteOpen(false)
  }

  const handleSwitchChange = (id) => (e) => {
    const value = e.target.checked ? 1 : 0
    setIsChecked(value)
    try {
      axios.put(
        'https://elwoodnelinka-4b15b25292df.herokuapp.com/editproductdisabled',
        {
          isChecked: value,
          id: id,
        }
      )
    } catch (error) {
      console.log(error)
    }
  }
  const handleChangeStatus = (id, newStatus) => {
    if (homeStatus && newStatus !== 0) return alert("You can't ")
    try {
      axios.put('https://elwoodnelinka-4b15b25292df.herokuapp.com/editProductStatus', {
        newStatus: newStatus,
        id: id,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleChangeStatusSellItems = (id, newStatus) => {
    if (saleItemsStatus && newStatus !== 0) return alert("You can't ")
    try {
      axios.put('https://elwoodnelinka-4b15b25292df.herokuapp.com/editProductStatus', {
        newStatus: newStatus,
        id: id,
      })
    } catch (error) {
      console.log(error)
    }
  }

  async function handleDeleteCategory(info) {
    info.imgs.map((item) => {
      const imageRefJpeg = ref(storage, item.jpeg)
      const imageRefWebp = ref(storage, item.webp)

      deleteObject(imageRefJpeg).then(() =>
        console.log('Jpeg has been delete sucess')
      )
      deleteObject(imageRefWebp).then(() =>
        console.log('Webp has been elete success')
      )
    })
    try {
      await axios.delete(
        `https://elwoodnelinka-4b15b25292df.herokuapp.com/deleteproduct/${info.itemId}`
      )
      const res = await axios.get(
        'https://elwoodnelinka-4b15b25292df.herokuapp.com/products'
      )
      const productsWithParsedJSON = res.data.map((item) => {
        const imgs = JSON.parse(item.imgs)
        const colors = JSON.parse(item.colors)
        const materials = JSON.parse(item.material)
        return {
          ...item,
          imgs,
          colors,
          material: materials,
        }
      })
      setProducts(productsWithParsedJSON)
    } catch (error) {
      console.log(error)
    }
  }

  const label = { inputProps: { 'aria-label': 'Switch demo' } }

  console.log('PRODUCTS')
  console.log(products)

  const columns = [
    {
      name: 'id',
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: 'Product',
      selector: (row) => (
        <div className={styles.tableProductColumn}>
          <img
            style={{ height: '40px', margin: '10px', borderRadius: '10px' }}
            src={
              webpSupported ? row?.imgs_arm[0]?.webp : row?.imgs_arm[0]?.jpeg
            }
            alt="Image"
            onClick={() => setTest(row)}
          />
          <Link to="?">{row.name_arm}</Link>
        </div>
      ),
    },
    {
      name: 'Colors',
      selector: (row) => (
        <div className={styles.colorRowContainer}>
          {Array.isArray(row.colors_arm) &&
            row.colors_arm.length > 0 &&
            row.colors_arm.map((itemColor, index) => (
              <div key={index} className={styles.colorContainer}>
                <div
                  style={{
                    backgroundColor: itemColor.color,
                    border: itemColor.color === '#ffffff' && '1px solid #ccc',
                  }}
                  className={styles.color}></div>
                <div className={styles.colorsNameContainer}>
                  <p className={styles.colorname}>{itemColor.name}</p>
                </div>
              </div>
            ))}
        </div>
      ),
    },
    {
      name: 'Price',
      selector: (row) => row.price_arm,
      sortable: true,
    },
    {
      name: 'Old Price',
      selector: (row) => (
        <div>{row.oldPrice_arm ? row.oldPrice_arm : <p>Don't have</p>}</div>
      ),
      sortable: true,
    },
    {
      name: 'Category',
      selector: (row) => (
        <div>
          {row &&
            row.category_arm &&
            Array.isArray(row.category_arm) &&
            row.category_arm.length > 0 &&
            row?.category_arm?.slice(0, -1).map((item) => item.category + ', ')}
          {row &&
            row.category_arm?.length &&
            row.category_arm[row.category_arm.length - 1].category}
        </div>
      ),
      sortable: true,
    },
    {
      name: 'Material',
      selector: (row) => (
        <div>
          {row &&
            Array.isArray(row.material_arm) &&
            row.material_arm.length > 0 &&
            row.material_arm.slice(0, -1).map((item) => item.material + ', ')}
          {row &&
            Array.isArray(row.material_arm) &&
            row.material_arm.length > 0 &&
            row.material_arm[row.material_arm.length - 1].material}
        </div>
      ),
      sortable: true,
    },

    {
      name: 'Height',
      selector: (row) => row.height_arm,
      sortable: true,
    },
    {
      name: 'Width',
      selector: (row) => row.width_arm,
      sortable: true,
    },
    {
      name: 'Depth',
      selector: (row) => row.depth_arm,
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => (
        <div className={styles.statusCont}>
          <StyledIconButton
            onClick={() => {
              handleChangeStatus(row.id, row.status === 1 ? 0 : 1)
              setTimeout(() => {
                window.location.reload()
              }, 1500)
            }}
            aria-label="delete">
            <Home color={row.status === 1 ? 'primary' : 'default'} />
          </StyledIconButton>
          <StyledIconButton
            onClick={() => {
              handleChangeStatusSellItems(row.id, row.status === 2 ? 0 : 2)
              setTimeout(() => {
                window.location.reload()
              }, 1500)
            }}
            aria-label="delete">
            <NewspaperIcon color={row.status === 2 ? 'primary' : 'default'} />
          </StyledIconButton>
        </div>
      ),
    },
    {
      name: 'Disabled',
      selector: (row) => (
        <div>
          {row.disabled ? (
            <Switch
              {...label}
              defaultChecked
              onChange={handleSwitchChange(row.id)}
            />
          ) : (
            <Switch {...label} onChange={handleSwitchChange(row.id)} />
          )}
        </div>
      ),
    },
    {
      name: 'Actions',
      selector: (row) => (
        <Stack direction="row" spacing={1}>
          <StyledIconButton
            onClick={(e) => {
              setItemId({ itemId: row.id, imgs: row.imgs_arm })
              showModal()
            }}
            aria-label="delete">
            <DeleteIcon color="error" />
          </StyledIconButton>
          <StyledIconButton aria-label="delete">
            <IconButton
              component={Link}
              to={`/admin/admindashboard/editProduct/${row.id}`}>
              <CreateIcon color="warning" />
            </IconButton>
          </StyledIconButton>
        </Stack>
      ),
    },
  ]

  const fetchProducts = async () => {
    setPreLoader(true)
    try {
      const res = await axios.get(
        'https://elwoodnelinka-4b15b25292df.herokuapp.com/products'
      )
      const homeStatusLength =
        res.data && res.data.filter((item) => +item.status === 1)
      const sellStatusLength =
        res.data && res.data.filter((item) => +item.status === 2)

      homeStatusLength.length === 4 ? setHomeStatus(true) : setHomeStatus(false)
      sellStatusLength.length === 3
        ? setSaleItemsStatus(true)
        : setSaleItemsStatus(false)
      const productsWithParsedJSON = res.data.map((item) => {
        console.log('PRODUCT WITH PARSED JSON')
        console.log(item)
        const imgs = item.imgs_arm ? JSON.parse(item.imgs_arm) : []
        const colors = item.colors_arm ? JSON.parse(item.colors_arm) : []
        const materials = item.material_arm ? JSON.parse(item.material_arm) : []
        const category = item.category_arm ? JSON.parse(item.category_arm) : []

        return {
          ...item,
          imgs_arm: imgs,
          colors_arm: colors,
          material_arm: materials,
          category_arm: category,
        }
      })
      setPreLoader(false)
      setProducts(productsWithParsedJSON)
    } catch (error) {
      console.log(error)
      setPreLoader(false)
    }
  }

  useEffect(() => {
    fetchProducts()
  }, [])

  useEffect(() => {
    const filtered = products?.filter((product) =>
      product?.name?.toLowerCase().includes(search?.toLowerCase())
    )
    setFilteredProducts(filtered)
  }, [search, products])

  useEffect(() => {
    setFilteredProducts(products)
  }, [])

  useEffect(() => {
    const canvas = document.createElement('canvas')
    if (canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0) {
      setWebpSupported(true)
    } else {
      setWebpSupported(false)
    }
  }, [])

  return (
    <div style={{ overflow: 'auto' }}>
      <DataTable
        title="Products List"
        columns={columns}
        data={search !== '' ? filteredProducts : products}
        fixedHeader
        fixedHeaderScrollHeight="450px"
        pagination
        selectableRowsHighlight
        highlightOnHover
        style={{ width: '1800px' }}
        selectableRowsNoSelectAll
        subHeaderWrap
        customStyles={{
          tableWrapper: {
            overflowX: 'auto',
          },
        }}
        actions={
          <div>
            <Stack spacing={2} direction="row">
              <Button variant="outlined">
                <Link to="/admin/admindashboard/addproduct">
                  Create Product
                </Link>
              </Button>
            </Stack>
          </div>
        }
        subHeader
        subHeaderComponent={
          <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off">
            <TextField
              id="standard-basic"
              label="Search Datas"
              value={search}
              variant="standard"
              color="success"
              onChange={(e) => setSearch(e.target.value)}
            />
          </Box>
        }
        subHeaderAlign="center"
      />
      <AntDModal
        title="You are sure delete category"
        open={deleteOpen}
        onOk={() => {
          handleDeleteCategory(itemId)
          hideModal()
        }}
        onCancel={hideModal}
        okText="Delete"
        cancelText="Cancel"></AntDModal>
      {preloader && <Loader />}
    </div>
  )
}
