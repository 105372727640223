import styles from './MainAcordeon.module.css'
import Acordeon from '../Acordeon/Acordeon'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { getDownloadURL, ref } from 'firebase/storage'
import storage from '../../firebase'
import Loader from '../Loader/Loader'
import { BiPlus } from 'react-icons/bi'
import { BsPersonVcard } from 'react-icons/bs'
import { IoChevronForward } from 'react-icons/io5'
import { useSelector } from 'react-redux'

export default function MainAcordeon() {
  const [image, setImage] = useState()
  const [webpSupported, setWebpSupported] = useState(null)
  const [loading, setLoading] = useState(true)
  const lang = useSelector((state) => state.lang.lang)
  const [no, setNo] = useState(3)
  const [infoAccordeon, setInfoAccordeon] = useState([])
  const [isActive, setIsActive] = useState('')

  useEffect(() => {
    if (lang === 'ENG') {
      setNo(1)
    } else if (lang === 'RUS') {
      setNo(2)
    } else if (lang === 'ARM') {
      setNo(3)
    }
  }, [no, lang])

  useEffect(() => {
    if (lang === 'ENG') {
      setNo(1)
    } else if (lang === 'RUS') {
      setNo(2)
    } else if (lang === 'ARM') {
      setNo(3)
    }
  }, [])

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const res = await axios.get(
          'https://elwoodnelinka-4b15b25292df.herokuapp.com/infoAccordeon'
        )
        const filteredData =
          res.data &&
          res.data.filter(
            (item) =>
              item.lang === lang.toLowerCase() &&
              Number(item?.no) === Number(no)
          )
        const slicedData = filteredData && filteredData.slice(0, 4)
        setInfoAccordeon(slicedData)
      } catch (error) {
        console.log(error)
      }
    }
    fetchPartners()
  }, [no])

  useEffect(() => {
    setLoading(true)
    const fetchInfoImg = async () => {
      try {
        const res = await axios.get(
          'https://elwoodnelinka-4b15b25292df.herokuapp.com/infoAccordeonImg'
        )
        if (res.data) {
          setImage(JSON.parse(res.data.img))
        }
        setLoading(false)
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }

    if (!image) {
      fetchInfoImg()
    } else {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    const canvas = document.createElement('canvas')
    if (canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0) {
      setWebpSupported(true)
    } else {
      setWebpSupported(false)
    }
  }, [])

  return (
    <>
      <section
        initial="hidden"
        whileInView="visible"
        className={styles.section}>
        <div className={styles.container}>
          <div className={styles.left}>
            <img
              className={styles.img}
              src={webpSupported ? image?.webp : image?.jpeg}
              alt="Image"
            />
          </div>
          <div className={styles.right}>
            <section
              className={styles.containerMain}
              viewport={{ amount: 0.2 }}
              initial="hidden"
              whileInView="visible">
              {infoAccordeon &&
                infoAccordeon.map((item, index) => (
                  <div
                    key={item.id}
                    className={`${styles.faq} ${
                      isActive === item.id && styles.active
                    }`}>
                    <div
                      className={styles.question}
                      onClick={() =>
                        setIsActive(isActive === item.id ? '' : item.id)
                      }>
                      <BsPersonVcard className={styles.mainIcon} />

                      <div className={styles.test}>
                        <h3 className={styles.miniTitle}>{item.title}</h3>

                        <div
                          className={`${styles.iconBox} ${
                            isActive === item.id ? styles.active : ''
                          }`}>
                          {isActive === item.id ? (
                            <IoChevronForward className={styles.icon} />
                          ) : (
                            <BiPlus className={styles.icon} />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={styles.answer}>
                      <p className={styles.description}>{item.description}</p>
                    </div>
                  </div>
                ))}
            </section>
          </div>
        </div>
      </section>
      {loading && <Loader />}
    </>
  )
}
