import styles from './Main.module.css'
import MainSlider from '../Slider/Slider'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Slider from 'react-slick'
import { useState, useEffect } from 'react'
import axios from 'axios'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import storage from '../../firebase'
import { ref, getDownloadURL, listAll, uploadBytes } from 'firebase/storage'
import Loader from '../Loader/Loader'
import Description from '../../pages/ProductPage/Description/Description'
import { useNavigate, Link } from 'react-router-dom'
import { Modal, Input, Form } from 'antd'
import { v4 as uuid } from 'uuid'
import imageCompression from 'browser-image-compression'
import { toast, ToastContainer } from 'react-toastify'
import ReCAPTCHA from 'react-google-recaptcha'
import emailjs from '@emailjs/browser'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import Avatar from '@mui/material/Avatar'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import styled from '@emotion/styled'
import { AiOutlineClose, AiOutlineCloudUpload } from 'react-icons/ai'
import TextareaAutosize from '@mui/material/TextareaAutosize'

const CustomTextField = styled(TextField)`
  & .Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-color: orange;
  }
`

export default function Main() {
  const { TextArea } = Input
  const navigate = useNavigate()
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const lang = useSelector((state) => state.lang.lang)
  const [width, setWidth] = useState(window.innerWidth)
  const [baners, setBaners] = useState([])
  const [imgLinks, setImgLink] = useState([])
  const [loading, setLoading] = useState(false)
  const [main, setMain] = useState([])
  const [webpSupported, setWebpSupported] = useState(false)
  const [productsCount, setProductsCount] = useState(0)
  const [clientsCount, setClintsCount] = useState(0)
  const [files, setFiles] = useState(null)
  const [img, setImg] = useState(null)
  const [captchaValue, setCaptchaValue] = useState('')
  const [open, setOpen] = React.useState(false)
  const theme = useTheme()
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    title: '',
    description: '',
  })
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const defaultTheme = createTheme()

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setValues({
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      title: '',
      description: '',
    })
    setFiles(null)
    setImg('')
  }

  const onInputChange = (e) => {
    setFiles(e.target.files[0])
  }

  function onChange(value) {
    setCaptchaValue(value)
  }

  const handleChangeValues = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  useEffect(() => {
    const getImgUrls = async () => {
      if (files === null) return
      const newName = files.name + uuid()

      try {
        const compressedImage = await imageCompression(files, {
          maxSize: 500 * 1024,
          maxWidthOrHeight: 800,
        })

        const jpegImageRef = ref(storage, `images/clients/${newName}.jpeg`)
        await uploadBytes(jpegImageRef, compressedImage).then(() =>
          console.log('Jpeg has been uploaded success !')
        )

        const webpImage = await createImageBitmap(files)
        const webpCanvas = document.createElement('canvas')
        webpCanvas.width = webpImage.width
        webpCanvas.height = webpImage.height
        const webpCanvasContext = webpCanvas.getContext('2d')
        webpCanvasContext.drawImage(webpImage, 0, 0)
        webpCanvas.toBlob(
          async (webpBlob) => {
            if (webpBlob) {
              const webpImageRef = ref(storage, `images/about/${newName}.webp`)
              await uploadBytes(webpImageRef, webpBlob).then(() =>
                toast.success('Images has been uploaded success!')
              )

              const jpegDownloadURL = await getDownloadURL(jpegImageRef)
              const webpDownloadURL = await getDownloadURL(webpImageRef)
              setImg({ jpeg: jpegDownloadURL, webp: webpDownloadURL })
            }
          },
          'image/webp',
          0.9
        )
      } catch (error) {
        console.log(error)
      }
    }
    getImgUrls()
  }, [files])

  const { t, i18n } = useTranslation()

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  function handleNavigateToAllProducts() {
    navigate('/allProducts')
  }

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const res = await axios.get(
          'https://elwoodnelinka-4b15b25292df.herokuapp.com/baners'
        )
        const datasParser =
          res.data &&
          res.data.map((item) => {
            return {
              id: item.id,
              img: JSON.parse(item.img),
            }
          })

        setBaners(datasParser)
      } catch (error) {
        console.log(error)
      }
    }
    fetchBanners()
  }, [])

  useEffect(() => {
    const fetchMain = async () => {
      try {
        const res = await axios.get(
          'https://elwoodnelinka-4b15b25292df.herokuapp.com/main'
        )
        const filteredData =
          res.data &&
          res.data.filter((item) => item.lang === lang.toLowerCase())

        setMain(filteredData)
      } catch (error) {
        console.log(error)
      }
    }
    fetchMain()
  }, [lang])

  useEffect(() => {
    const fetchMain = async () => {
      try {
        const res = await axios.get(
          'https://elwoodnelinka-4b15b25292df.herokuapp.com/main'
        )
        const filteredData =
          res.data &&
          res.data.filter((item) => item.lang === lang.toLowerCase())

        setMain(filteredData)
      } catch (error) {
        console.log(error)
      }
    }
    fetchMain()
  }, [])

  // useEffect(() => {
  //   async function handleLogout() {
  //     try {
  //       await axios.put('https://elwoodnelinka-4b15b25292df.herokuapp.com/isAdmin', {
  //         isAdmin: 0,
  //       })
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }

  //   handleLogout()
  // }, [])

  const handleSend = (e) => {
    e.preventDefault()

    emailjs
      .send(
        'service_80tsioa',
        'template_b2ibk0x',
        {
          client_fullName: `${values.firstName} ${values.lastName}`,
          client_number: values.phoneNumber,
          client_email: values.email,
          client_productTitle: values.title,
          client_productDescription: values.description,
          client_image: img?.jpeg,
        },
        'kQ5KUgyZyl3oPWFpd'
      )
      .then(() => {
        // toast.success('The order has been registered', {
        //   position: toast.POSITION.BOTTOM_LEFT,
        // })
        setValues({
          firstName: '',
          lastName: '',
          phoneNumber: '',
          email: '',
          title: '',
          description: '',
        })
        setFiles(null)
        setImg('')
        setOpen(false)
      })
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    touchMove: true,
  }

  useEffect(() => {
    const canvas = document.createElement('canvas')
    if (canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0) {
      setWebpSupported(true)
    } else {
      setWebpSupported(false)
    }
  }, [])

  useEffect(() => {
    const fetchInformation = async () => {
      try {
        const { data } = await axios.get(
          'https://elwoodnelinka-4b15b25292df.herokuapp.com/information'
        )
        if (data) {
          setProductsCount(data[0].completedProjects)
          setClintsCount(data[0].clients)
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchInformation()
  }, [])

  return (
    <>
      {loading && <Loader />}
      <section className={styles.section1}>
        <div className={styles.container}>
          <div className={styles.left}>
            <div className={styles.mainContent}>
              {main &&
                main.map((item) => (
                  <div key={item.id}>
                    {' '}
                    <div key={item.id} className={styles.title}>
                      {item?.title}
                    </div>
                    <p className={styles.desc}>
                      <Description desc={item?.description.replace(/"/g, '')} />
                    </p>
                  </div>
                ))}
              <div
                className={`${styles.btnsContainer} ${
                  lang === 'RUS' && styles.rushopNow
                }`}>
                <Link to="/allProducts">
                  <button className={styles.shopNow}>{t('showNow')}</button>
                </Link>
                <button className={styles.order} onClick={handleClickOpen}>
                  {t('order')}
                </button>
              </div>
            </div>
            <div className={styles.infoContainer}>
              <div
                className={`${styles.infoItem} ${
                  lang === 'ARM' && styles.miniItem
                }`}>
                <h2
                  className={`${styles.infoDesc} ${
                    lang === 'ARM' && styles.miniTitle
                  }`}>
                  15+
                </h2>
                <span
                  className={`${styles.infoDesc} ${
                    lang === 'ARM' && styles.miniDescription
                  }`}>
                  {t('yearExperience')}
                </span>
              </div>
              <div
                className={`${styles.infoItem} ${
                  lang === 'ARM' && styles.miniItem
                }`}>
                <h2
                  className={`${styles.infoDesc} ${
                    lang === 'ARM' && styles.miniTitle
                  }`}>
                  {+productsCount > 0 && productsCount}
                </h2>
                <span
                  className={`${styles.infoDesc} ${
                    lang === 'ARM' && styles.miniDescription
                  }`}>
                  {t('completedProjects')}
                </span>
              </div>
              <div
                className={`${styles.infoItem} ${
                  lang === 'ARM' && styles.miniItem
                }`}>
                <h2
                  className={`${styles.infoDesc} ${
                    lang === 'ARM' && styles.miniTitle
                  }`}>
                  {+clientsCount > 0 && clientsCount}
                </h2>
                <span
                  className={`${styles.infoDesc} ${
                    lang === 'ARM' && styles.miniDescription
                  }`}>
                  {t('clients')}
                </span>
              </div>
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.rightContainer}>
              {width > 768 && <MainSlider />}
              {width < 768 && (
                <Slider {...settings}>
                  {baners.length !== 0 &&
                    baners.map((image, index) => (
                      <div key={index}>
                        <img
                          onClick={handleNavigateToAllProducts}
                          src={
                            webpSupported ? image?.img?.webp : image?.img?.jpeg
                          }
                          alt={`Baner No ${index + 1}`}
                          className={styles.adaptiveSliderImg}
                        />
                      </div>
                    ))}
                </Slider>
              )}
            </div>
          </div>
        </div>
      </section>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title">
        <Box sx={{ textAlign: 'center' }}>
          <DialogTitle id="responsive-dialog-title">
            {t('quickOrder')}
          </DialogTitle>
        </Box>
        <DialogContent>
          <ThemeProvider theme={defaultTheme}>
            <Container maxWidth="xs">
              <Box
                component="form"
                noValidate
                // onSubmit={handleSubmit}
                sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="given-name"
                      name="firstName"
                      required
                      fullWidth
                      id="firstName"
                      label={t('firstName')}
                      autoFocus
                      color="warning"
                      value={values.firstName}
                      onChange={handleChangeValues}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      id="lastName"
                      label={t('lastName')}
                      name="lastName"
                      autoComplete="family-name"
                      color="warning"
                      value={values.lastName}
                      onChange={handleChangeValues}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextField
                      required
                      fullWidth
                      id="email"
                      label={t('email')}
                      name="email"
                      autoComplete="email"
                      color="warning"
                      value={values.email}
                      onChange={handleChangeValues}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextField
                      required
                      fullWidth
                      name="phoneNumber"
                      label={t('phoneNumber')}
                      type="text"
                      id="phoneNumber"
                      autoComplete="phoneNumber"
                      color="warning"
                      value={values.phoneNumber}
                      onChange={handleChangeValues}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextField
                      required
                      fullWidth
                      name="title"
                      label={t('titleOrder')}
                      type="text"
                      id="title"
                      autoComplete="title"
                      color="warning"
                      value={values.title}
                      onChange={handleChangeValues}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      minRows={3}
                      label={t('moreInfo')}
                      style={{ width: '100%' }}
                      value={values.description}
                      onChange={handleChangeValues}
                      name="description"
                      color="warning"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div className={styles.mainImgContainer}>
                      <div className={styles.uploadImgContainer}>
                        <input
                          type="file"
                          id="file"
                          accept="image/*"
                          hidden
                          onChange={onInputChange}
                        />
                        <div
                          onClick={() =>
                            document.querySelector('#file').click()
                          }
                          className={`${styles.imgArea} ${
                            img ? styles.active : ''
                          }`}
                          data-img="">
                          {img?.jpeg ? (
                            <div>
                              <img
                                src={img?.jpeg}
                                alt="Selected"
                                className={styles.img}
                              />
                            </div>
                          ) : (
                            <>
                              <AiOutlineCloudUpload
                                className={styles.iconImgContainer}
                              />
                              <h3>Upload Your Image</h3>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={17}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                      }}>
                      <ReCAPTCHA
                        sitekey="6Lei1ZMnAAAAADJRZ-JGEJT3KEddyaebALuZZooq"
                        onChange={onChange}
                        size={windowWidth > 438 ? 'normal' : 'compact'}
                        theme="dark"
                      />
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </ThemeProvider>
        </DialogContent>
        <DialogActions>
          <Button autoFocus color="warning" onClick={handleClose}>
            {t('cancel')}
          </Button>
          <Button
            onClick={handleSend}
            autoFocus
            color="warning"
            variant="contained"
            disabled={
              !values.firstName ||
              !values.lastName ||
              !values.phoneNumber ||
              !values.email ||
              !values.title ||
              !captchaValue ||
              !img ||
              !values.description
            }>
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
