import DataTable from 'react-data-table-component'
import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Switch from '@mui/material/Switch'
import { IconButton } from '@mui/material'
import { styled } from '@mui/system'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import SendIcon from '@mui/icons-material/Send'
import styles from './AdminMaterials.module.css'
import LoadingButton from '@mui/lab/LoadingButton'
import axios from 'axios'
import DeleteIcon from '@mui/icons-material/Delete'
import CreateIcon from '@mui/icons-material/Create'
import TextField from '@mui/material/TextField'
import { Modal as AntDModal } from 'antd'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../Loader/Loader'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: -10,
  p: 4,
}

export default function AdminMaterials() {
  const [itemId, setItemId] = useState(null)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [materials, setMaterials] = useState([])
  const [material, setMaterial] = useState({
    material: '',
    disabled: 0,
    lang: '',
  })
  const [sendColorLoading, setSendColorLoading] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [editOpen, setEditOpen] = useState(false)
  const [editValue, setEditValue] = useState('')
  const [editMaterialId, setEditMaterialId] = useState(null)
  const [editMaterialLoading, setEditMaterialLoading] = useState(false)

  const StyledIconButton = styled(IconButton)({
    '&:hover': {
      color: 'red',
    },
  })

  async function handleDeleteCategory(id) {
    try {
      await axios
        .delete(
          `https://elwoodnelinka-4b15b25292df.herokuapp.com/deletematerial/${id}`
        )
        .then(() => toast.success('Material deleted success !'))
      const updatedMaterials = await axios.get(
        'https://elwoodnelinka-4b15b25292df.herokuapp.com/materials'
      )
      setMaterials(updatedMaterials.data)
    } catch (error) {
      toast.error(error.message)
    }
  }

  console.log(material)

  const handleSwitchChange = (id) => (e) => {
    const value = e.target.checked ? 1 : 0
    setIsChecked(value)
    setItemId(id)
    try {
      axios
        .put('https://elwoodnelinka-4b15b25292df.herokuapp.com/editmaterial', {
          isChecked: value,
          id: id,
        })
        .then(() => toast.success('Material edited success'))
    } catch (error) {
      toast.error(error.message)
    }
  }

  const handleChange = (e) => {
    setMaterial((prev) => ({ ...prev, material: e.target.value }))
  }
  const handleChangeLang = (e) => {
    setMaterial((prev) => ({ ...prev, lang: e.target.value }))
  }

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    setMaterial({
      material: '',
      disabled: 0,
      lang: '',
    })
  }

  const handleCloseEdit = () => {
    setEditOpen(false)
    setEditValue('')
    setEditMaterialId(null)
  }

  const showModal = () => {
    setDeleteOpen(true)
  }

  const hideModal = () => {
    setDeleteOpen(false)
  }

  const label = { inputProps: { 'aria-label': 'Switch demo' } }
  const columns = [
    {
      name: 'Id',
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: 'Material',
      selector: (row) => row.material,
    },
    {
      name: 'Material',
      selector: (row) => (row?.lang ? row.lang : 'No Language'),
    },
    {
      name: 'Disabled',
      selector: (row) => (
        <div>
          {row.disabled ? (
            <Switch
              {...label}
              defaultChecked
              onChange={handleSwitchChange(row.id)}
            />
          ) : (
            <Switch {...label} onChange={handleSwitchChange(row.id)} />
          )}
        </div>
      ),
    },
    {
      name: 'Actions',
      selector: (row) => (
        <Stack direction="row" spacing={1}>
          <StyledIconButton aria-label="delete">
            <DeleteIcon
              color="error"
              onClick={() => {
                setItemId(row.id)
                showModal()
              }}
            />
          </StyledIconButton>
          <StyledIconButton aria-label="edit">
            <CreateIcon
              color="warning"
              onClick={(e) => {
                setEditMaterialId(row.id)
                setEditOpen(true)
              }}
            />
          </StyledIconButton>
        </Stack>
      ),
    },
  ]

  const fetchMaterials = async () => {
    try {
      const res = await axios.get(
        'https://elwoodnelinka-4b15b25292df.herokuapp.com/materials'
      )
      res.data && setMaterials(res.data)
      setLoading(false)
    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchMaterials()
  }, [])

  async function handleClick(e) {
    e.preventDefault()
    setSendColorLoading(true)
    try {
      await axios
        .post('https://elwoodnelinka-4b15b25292df.herokuapp.com/addmaterial', material)
        .then(() => toast.success('Material created successfully !'))
      const updatedMaterials = await axios.get(
        'https://elwoodnelinka-4b15b25292df.herokuapp.com/materials'
      )
      setMaterials(updatedMaterials.data)
      setSendColorLoading(false)
      setMaterial({
        material: '',
        disabled: 0,
        lang: '',
      })
      setOpen(false)
    } catch (error) {
      toast.error(error.message)
    }
  }

  const handleEditMaterial = async () => {
    try {
      setEditMaterialLoading(true)
      await axios
        .put(
          `https://elwoodnelinka-4b15b25292df.herokuapp.com/editMaterialName/${editMaterialId}`,
          {
            newMaterial: editValue,
          }
        )
        .then(({ data }) => {
          toast.success(data, {
            toastClassName: styles.myToast,
          })
          handleCloseEdit()
          fetchMaterials()
          setEditMaterialLoading(false)
        })
        .catch((error) => console.log(error.message))
    } catch (error) {
      console.log('UPDATE ERROR CATEGORY')
      console.log(error.message)
    }
  }

  return (
    <>
      <DataTable
        title="Materials List"
        columns={columns}
        data={materials}
        fixedHeader
        fixedHeaderScrollHeight="450px"
        pagination
        selectableRows
        selectableRowsHighlight
        highlightOnHover
        actions={
          <div>
            <Stack spacing={2} direction="row">
              <Button variant="outlined" onClick={handleOpen}>
                + Add Material
              </Button>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    className={styles.titleModal}>
                    Create New Material
                  </Typography>
                  <Box
                    className={styles.addInpMaterialContainer}
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off">
                    <TextField
                      id="standard-basic"
                      label="Add Material"
                      value={material.material}
                      variant="standard"
                      color="primary"
                      name="category"
                      onChange={handleChange}
                    />
                  </Box>
                  <Box
                    className={styles.addInpMaterialContainer}
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off">
                    <TextField
                      id="standard-basic"
                      label="Language Variant"
                      value={material.lang}
                      variant="standard"
                      color="primary"
                      name="lang"
                      onChange={handleChangeLang}
                    />
                  </Box>
                  <Box className={styles.sendButtonContainer}>
                    <Box sx={{ '& > button': { m: 1 } }}>
                      <LoadingButton
                        onClick={handleClick}
                        endIcon={<SendIcon />}
                        loading={sendColorLoading}
                        loadingPosition="end"
                        variant="contained">
                        <span>Send</span>
                      </LoadingButton>
                    </Box>
                  </Box>
                </Box>
              </Modal>
            </Stack>
          </div>
        }
        subHeader
        subHeaderAlign="center"
      />
      <AntDModal
        title="You are sure delete category"
        open={deleteOpen}
        onOk={() => {
          handleDeleteCategory(itemId)
          hideModal()
        }}
        onCancel={hideModal}
        okText="Delete"
        cancelText="Cancel"></AntDModal>
      <ToastContainer />
      <Modal
        open={editOpen}
        onClose={handleCloseEdit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className={styles.titleModal}>
            Update Material
          </Typography>
          <Box
            className={styles.addInpMaterialContainer}
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off">
            <TextField
              id="standard-basic"
              label="Edit Material "
              value={editValue}
              variant="standard"
              color="primary"
              name="category"
              onChange={(e) => setEditValue(e.target.value)}
            />
          </Box>
          <Box className={styles.sendButtonContainer}>
            <Box sx={{ '& > button': { m: 1 } }}>
              <LoadingButton
                onClick={handleEditMaterial}
                endIcon={<SendIcon />}
                loading={editMaterialLoading}
                loadingPosition="end"
                variant="contained">
                <span>Update</span>
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Modal>
      {loading && <Loader />}
    </>
  )
}
