import { useRef, useState, useEffect } from 'react'
import styles from './Products.module.css'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setProducts } from '../../store/sections/sectionsAction'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import ProductCard from '../ProductCard/ProductCard'

const itemsAnimation = {
  hidden: {
    y: 100,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
}
const buttonAnimation = {
  hidden: {
    y: 100,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
  },
}
const titleAnimation = {
  hidden: {
    y: -100,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
  },
}

export default function Products() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [hiteProducts, setHiteProducts] = useState([])
  const productsRef = useRef(null)
  const lang = useSelector((state) => state.lang.lang.toLowerCase())
  const { t, i18n } = useTranslation()

  useEffect(() => {
    if (productsRef.current) {
      dispatch(setProducts(productsRef))
    }
  }, [dispatch])

  function handleNavigateToAllProductPage(id) {
    navigate(`/allProducts/product/${id}`)
  }

  const fetchHiteProducts = async () => {
    try {
      const res = await axios.get(
        'https://elwoodnelinka-4b15b25292df.herokuapp.com/products'
      )
      const langLowCase = lang.toLowerCase()

      const productsWithParsedJSON = res.data
        .map((item) => {
          const imgs = item[`imgs_${langLowCase}`]
            ? JSON.parse(item[`imgs_${langLowCase}`])
            : []
          const colors = item[`colors_${langLowCase}`]
            ? JSON.parse(item[`colors_${langLowCase}`])
            : []
          const category = item[`category_${langLowCase}`]
            ? JSON.parse(item[`category_${langLowCase}`])
            : []

          const categoryItem = category.filter(
            (elem) => elem.lang === langLowCase
          )

          const categoryValue =
            categoryItem.length > 0 ? categoryItem[0].category : null

          return {
            ...item,
            allCategories: category,
            [`imgs_${langLowCase}`]: imgs,
            [`colors_${langLowCase}`]: colors,
            [`category_${langLowCase}`]: categoryValue,
          }
        })
        .filter((product) => product.status === 1 && !product.disabled)

      setHiteProducts(productsWithParsedJSON.slice(0, 4))
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchHiteProducts()
  }, [])

  useEffect(() => {
    fetchHiteProducts()
  }, [lang])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // useEffect(() => {
  //   setHiteProducts((prevProducts) =>
  //     prevProducts.map((product) => {
  //       const updatedCategories = product.allCategories
  //         .map((category) => {
  //           if (category.lang === lang.toLowerCase()) {
  //             return category
  //           }
  //           return null
  //         })
  //         .filter((category) => category !== null)

  //       return {
  //         ...product,
  //         category:
  //           updatedCategories.length > 0 ? updatedCategories[0].category : null,
  //       }
  //     })
  //   )
  // }, [lang])

  return (
    <motion.div
      viewport={{ amount: 0.2 }}
      initial="hidden"
      whileInView="visible">
      <motion.h1
        variants={titleAnimation}
        ref={productsRef}
        className={styles.titleProducts}>
        {t('products')}
      </motion.h1>
      <section
        viewport={{ amount: 0.2 }}
        initial="hidden"
        whileInView="visible"
        className={styles.section}>
        <div className={styles.container}>
          <div className={styles.productsContainer}>
            {Array.isArray(hiteProducts) &&
              hiteProducts.length > 0 &&
              hiteProducts.map((product, index) => (
                <ProductCard
                  index={index}
                  item={product}
                  variantItemsAnimation={itemsAnimation}
                />
              ))}
          </div>
          <motion.div
            variants={buttonAnimation}
            className={styles.btnContainer}>
            <Link to="/allProducts" className={styles.seeAllBtn}>
              {t('seeAll')}
            </Link>
          </motion.div>
        </div>
      </section>
    </motion.div>
  )
}
