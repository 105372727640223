import styles from './AdminInfoAccordeon.module.css'
import { useEffect, useState } from 'react'
import { BiPlus } from 'react-icons/bi'
import { BsPersonVcard } from 'react-icons/bs'
import { IoChevronForward } from 'react-icons/io5'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import axios from 'axios'
import Stack from '@mui/material/Stack'
import LoadingButton from '@mui/lab/LoadingButton'
import SendIcon from '@mui/icons-material/Send'
import { AiOutlineCloudUpload, AiOutlineClose } from 'react-icons/ai'
import { v4 as uuid } from 'uuid'
import storage from '../../firebase'
import {
  getDownloadURL,
  deleteObject,
  ref,
  uploadBytes,
} from 'firebase/storage'
import { toast, ToastContainer } from 'react-toastify'
import imageCompression from 'browser-image-compression'
import { Modal as AntDModal } from 'antd'
import Loader from '../../components/Loader/Loader'

export default function AdminInfoAccordeon() {
  const [preLoader, setPreLoader] = useState(true)
  const [isActive, setIsActive] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingEdit, setLoadingEdit] = useState(false)
  const [infoAccordeon, setInfoAccordeon] = useState([])
  const [lang, setLang] = useState('ENG')
  const [open, setOpen] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [image, setImage] = useState(null)
  const [imageList, setImageList] = useState([])
  const [imageLink, setImgLink] = useState('')
  const [infoId, setInfoId] = useState(null)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deleteImgOpen, setDeleteImgOpen] = useState(false)
  const [itemId, setItemId] = useState(null)
  const [webpSupported, setWebpSupported] = useState(false)
  const [editValues, setEditValues] = useState({
    title: '',
    description: '',
    no: 0,
  })
  const [values, setValues] = useState({
    title: '',
    description: '',
    no: 0,
  })
  function onInputChange(e) {
    setImage(e.target.files[0])
  }

  const showModal = () => {
    setDeleteOpen(true)
  }

  const hideModal = () => {
    setDeleteOpen(false)
  }

  const showModalImg = () => {
    setDeleteImgOpen(true)
  }

  const hideModalImg = () => {
    setDeleteImgOpen(false)
  }

  console.log('INFO ACCORDEON', infoAccordeon)

  async function onSubmit(e) {
    e.preventDefault()

    if (image === null) return
    const newName = image.name + uuid()

    try {
      const compressedImage = await imageCompression(image, {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 800,
      })

      const jpegImageRef = ref(storage, `images/imgAccordeon/${newName}.jpeg`)
      await uploadBytes(jpegImageRef, compressedImage)
      console.log('Success !')

      const webpImage = await createImageBitmap(image)
      const webpCanvas = document.createElement('canvas')
      webpCanvas.width = webpImage.width
      webpCanvas.height = webpImage.height
      const webpCanvasContext = webpCanvas.getContext('2d')
      webpCanvasContext.drawImage(webpImage, 0, 0)

      const webpBlob = await new Promise((resolve) => {
        webpCanvas.toBlob((blob) => resolve(blob), 'image/webp', 0.9)
      })

      if (webpBlob) {
        const webpImageRef = ref(storage, `images/imgAccordeon/${newName}.webp`)
        await uploadBytes(webpImageRef, webpBlob)
        toast.success('Image has been created success!')

        const jpegDownloadURL = await getDownloadURL(jpegImageRef)
        const webpDownloadURL = await getDownloadURL(webpImageRef)
        const imgObj = { jpeg: jpegDownloadURL, webp: webpDownloadURL }

        try {
          await axios.post(
            'https://elwoodnelinka-4b15b25292df.herokuapp.com/addinfoAccordeonImg',
            {
              img: JSON.stringify(imgObj),
            }
          )
          fetchImgLink()
          toast.success('Img edited successfully!')
        } catch (error) {
          console.log(error)
          toast.error(error.message)
        }
      }
    } catch (error) {
      console.log(error)
      toast.error(error.message)
    }
  }

  async function handleClickOpenEdit(item) {
    setOpenEdit(true)
    setEditValues({
      title: item.title,
      description: item.description,
      no: item.no,
      lang: item.lang,
    })
  }

  function handleCloseEdit() {
    setOpenEdit(false)
    setEditValues({ title: '', description: '', no: 0 })
  }

  const fetchImgLink = async () => {
    try {
      const res = await axios.get(
        'https://elwoodnelinka-4b15b25292df.herokuapp.com/infoAccordeonImg'
      )

      if (res.data) {
        const infoAccordeonImg = JSON.parse(res.data.img)
        const { id } = res.data

        setImageList({ id, img: infoAccordeonImg })
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    fetchImgLink()
  }, [])

  useEffect(() => {
    if (lang === 'ENG') {
      setValues((prev) => ({ ...prev, no: 1 }))
    } else if (lang === 'RUS') {
      setValues((prev) => ({ ...prev, no: 2 }))
    } else if (lang === 'ARM') {
      setValues((prev) => ({ ...prev, no: 3 }))
    }
  }, [lang])

  const handleClickOpen = () => {
    setOpen(true)
  }

  function handleChangeValues(e) {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }
  function handleChangeValuesEdit(e) {
    setEditValues((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const handleClose = () => {
    setOpen(false)
  }

  function handleChange(e) {
    setLang(e.target.value)
  }

  const fetchInfos = async () => {
    setPreLoader(true)
    try {
      const res = await axios.get(
        'https://elwoodnelinka-4b15b25292df.herokuapp.com/infoAccordeon'
      )
      const filteredDatas =
        res.data &&
        res.data.filter(
          (item) => item.lang === lang.toLowerCase() && +item.no === values.no
        )

      setInfoAccordeon(filteredDatas.slice(0, 4))
      setPreLoader(false)
    } catch (error) {
      toast.error(error.message)
      setPreLoader(false)
    }
  }

  useEffect(() => {
    fetchInfos()
  }, [values.no])

  useEffect(() => {
    fetchInfos()
  }, [])

  async function sendingValues() {
    setLoading(true)
    try {
      await axios
        .post('https://elwoodnelinka-4b15b25292df.herokuapp.com/addInfoAccordeon', {
          ...values,
          lang: lang.toLowerCase(),
        })
        .then(() => fetchInfos())
        .then(() => toast.success('Info Accordeon has been created sucess !'))
      setOpen(false)
      setLoading(false)
      setTimeout(() => {
        setValues((prev) => ({ ...prev, title: '', description: '' }))
      }, 1000)
      console.log(values)
      console.log('VALUES')
    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    }
  }

  async function handleDelete(id) {
    try {
      await axios
        .delete(
          `https://elwoodnelinka-4b15b25292df.herokuapp.com/deleteInfoAccordeon/${id}`
        )
        .then(() => fetchInfos())
        .then(() => toast.success('Info Accordeon deleted sucess !'))
    } catch (error) {
      toast.error(error.message)
    }
  }

  async function sendValuesEdit(id) {
    try {
      await axios
        .put(
          `https://elwoodnelinka-4b15b25292df.herokuapp.com/editInfoAccordeon/${id}`,
          {
            ...editValues,
          }
        )
        .then(() => {
          setLoadingEdit(false)
          setOpenEdit(false)
          setEditValues({
            title: '',
            description: '',
            no: 0,
          })
        })
        .then(() => fetchInfos())
    } catch (error) {
      toast.error(error.message)
    }
  }

  async function handleDeleteImg(info) {
    const imageRefJpeg = ref(storage, info?.img?.jpeg)
    const imageRefWebp = ref(storage, info?.img?.webp)

    deleteObject(imageRefJpeg).then(() =>
      console.log('Jpeg has been delete sucess')
    )
    deleteObject(imageRefWebp).then(() =>
      console.log('Webp has been delete success')
    )
    console.log(info)
    try {
      await axios.delete(
        `https://elwoodnelinka-4b15b25292df.herokuapp.com/deletenfoAccordeonImg/${info.itemId}`
      )
      fetchImgLink()
      toast.success('Img deleted success !')
    } catch (error) {
      toast.error(error.message)
    }
  }

  async function handleEditImg(id) {
    if (image === null) return
    const newName = image.name + uuid()

    try {
      const compressedImage = await imageCompression(image, {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 800,
      })

      const jpegImageRef = ref(storage, `images/imgAccordeon/${newName}.jpeg`)
      await uploadBytes(jpegImageRef, compressedImage)
      console.log('Success !')

      const webpImage = await createImageBitmap(image)
      const webpCanvas = document.createElement('canvas')
      webpCanvas.width = webpImage.width
      webpCanvas.height = webpImage.height
      const webpCanvasContext = webpCanvas.getContext('2d')
      webpCanvasContext.drawImage(webpImage, 0, 0)

      const webpBlob = await new Promise((resolve) => {
        webpCanvas.toBlob((blob) => resolve(blob), 'image/webp', 0.9)
      })

      if (webpBlob) {
        const webpImageRef = ref(storage, `images/imgAccordeon/${newName}.webp`)
        await uploadBytes(webpImageRef, webpBlob)
        toast.success('Image has been edit success !')

        const jpegDownloadURL = await getDownloadURL(jpegImageRef)
        const webpDownloadURL = await getDownloadURL(webpImageRef)
        const imgObj = { jpeg: jpegDownloadURL, webp: webpDownloadURL }

        try {
          await axios
            .put(
              `https://elwoodnelinka-4b15b25292df.herokuapp.com/editInfoAccordeonImg/${id}`,
              {
                img: JSON.stringify(imgObj),
              }
            )
            .then(() => {
              toast.success('Img has been edited success !')
              setTimeout(() => {
                window.location.reload()
              }, 1000)
            })
        } catch (error) {
          toast.error(error.message)
        }
      }
    } catch (error) {
      console.log(error)
      toast.error(error.message)
    }
  }

  useEffect(() => {
    const canvas = document.createElement('canvas')
    if (canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0) {
      setWebpSupported(true)
    } else {
      setWebpSupported(false)
    }
  }, [])

  return (
    <div className={styles.wrapper}>
      <h2>Home Page Section 2</h2>

      <div className={styles.uploadImgContainer}>
        <input
          type="file"
          id="file"
          accept="image/*"
          hidden
          onChange={onInputChange}
        />
        <div
          className={`${styles.imgArea} ${image ? styles.active : ''}`}
          data-img="">
          {imageList.hasOwnProperty('id') ? (
            <div>
              <img
                src={
                  webpSupported ? imageList?.img?.webp : imageList?.img?.jpeg
                }
                alt="Selected"
                className={styles.img}
              />
              <AiOutlineClose className={styles.deleteImg} />
            </div>
          ) : (
            <>
              <AiOutlineCloudUpload />
              <h3>Upload Image</h3>
              <p>
                Image size must be less than <span>2MB</span>
              </p>
            </>
          )}
        </div>
        <button
          className={styles.selectImage}
          onClick={() => document.querySelector('#file').click()}>
          Select Image
        </button>
        <div className={styles.btnContainer}>
          <div
            onClick={onSubmit}
            className={`${styles.button} ${styles.button4}`}>
            Add
          </div>
          <div
            onClick={() => {
              setItemId({ itemId: imageList?.id, img: imageList?.img })
              showModalImg()
            }}
            className={`${styles.button} ${styles.button2}`}>
            Delete
          </div>
          <div
            onClick={() => {
              handleEditImg(imageList?.id)
            }}
            className={`${styles.button} ${styles.button3}`}>
            Edit
          </div>
        </div>
      </div>
      <div className={styles.actions}>
        <div className={styles.languageContainer}>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Test</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={lang}
                label="Age"
                onChange={handleChange}>
                <MenuItem value="ENG">ENG</MenuItem>
                <MenuItem value={'ARM'}>ARM</MenuItem>
                <MenuItem value="RUS">RUS</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>
      </div>
      <div className={styles.container}>
        {infoAccordeon?.length > 0 &&
          infoAccordeon !== [] &&
          infoAccordeon.map((item) => (
            <div
              key={item.id}
              className={`${styles.faq} ${
                isActive === item.id && styles.active
              }`}>
              <div className={styles.btns}>
                <div
                  onClick={() => {
                    setItemId(item.id)
                    showModal()
                  }}
                  className={`${styles.button} ${styles.button2}`}>
                  Delete
                </div>
                <div
                  onClick={() => {
                    setInfoId(item.id)
                    handleClickOpenEdit(item)
                  }}
                  className={`${styles.button} ${styles.button3}`}>
                  Edit
                </div>
              </div>
              <div
                className={styles.question}
                onClick={() =>
                  setIsActive(isActive === item.id ? '' : item.id)
                }>
                <BsPersonVcard className={styles.mainIcon} />

                <div className={styles.test}>
                  <h3 className={styles.miniTitle}>{item.title}</h3>

                  <div
                    className={`${styles.iconBox} ${
                      isActive === item.id ? styles.active : ''
                    }`}>
                    {isActive === item.id ? (
                      <IoChevronForward className={styles.icon} />
                    ) : (
                      <BiPlus className={styles.icon} />
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.answer}>
                <p>{item.description}</p>
              </div>
            </div>
          ))}
        {infoAccordeon.length < 4 && (
          <div onClick={handleClickOpen} className={styles.addBtnContainer}>
            <div className={`${styles.button} ${styles.button4}`}>
              Add new info accordeon
            </div>
          </div>
        )}

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Add {lang} variant</DialogTitle>
          <div style={{ padding: '16px' }}>
            <TextField
              required
              autoFocus
              margin="dense"
              value={values.title}
              onChange={handleChangeValues}
              name="title"
              id="location"
              label="Title"
              type="text"
              fullWidth
              variant="standard"
              style={{ marginBottom: '16px' }}
            />
            <TextField
              required
              autoFocus
              value={values.description}
              onChange={handleChangeValues}
              name="description"
              margin="dense"
              id="description"
              label="Description"
              type="email"
              fullWidth
              multiline
              rows={2}
              maxRows={4}
              variant="standard"
              style={{ marginBottom: '60px' }}
            />

            <Stack direction="row" spacing={2}>
              <LoadingButton onClick={handleClose} variant="outlixaned">
                Cancel
              </LoadingButton>
              <LoadingButton
                onClick={sendingValues}
                endIcon={<SendIcon />}
                loading={loading}
                variant="contained">
                <span>Send</span>
              </LoadingButton>
            </Stack>
          </div>
        </Dialog>
        <Dialog open={openEdit} onClose={handleCloseEdit}>
          <DialogTitle>Edit {lang} variant</DialogTitle>
          <div style={{ padding: '16px' }}>
            <TextField
              required
              autoFocus
              margin="dense"
              value={editValues.title}
              onChange={handleChangeValuesEdit}
              name="title"
              id="location"
              label="Title"
              type="text"
              fullWidth
              variant="standard"
              style={{ marginBottom: '16px' }}
            />
            <TextField
              required
              autoFocus
              value={editValues.description}
              onChange={handleChangeValuesEdit}
              name="description"
              margin="dense"
              id="description"
              label="Description"
              type="text"
              fullWidth
              multiline
              rows={4}
              maxRows={4}
              variant="standard"
              style={{ marginBottom: '60px' }}
            />

            <Stack direction="row" spacing={2}>
              <LoadingButton onClick={handleCloseEdit} variant="outlixaned">
                Cancel
              </LoadingButton>
              <LoadingButton
                onClick={() => sendValuesEdit(infoId)}
                endIcon={<SendIcon />}
                loading={loadingEdit}
                variant="contained">
                <span>Edit</span>
              </LoadingButton>
            </Stack>
          </div>
        </Dialog>
      </div>
      <AntDModal
        title="You are sure"
        open={deleteOpen}
        onOk={() => {
          handleDelete(itemId)
          hideModal()
        }}
        onCancel={hideModal}
        okText="Delete"
        cancelText="Cancel"></AntDModal>
      <AntDModal
        title="You are sure"
        open={deleteImgOpen}
        onOk={() => {
          handleDeleteImg(itemId)
          hideModalImg()
        }}
        onCancel={hideModalImg}
        okText="Delete"
        cancelText="Cancel"></AntDModal>
      <ToastContainer />
      {preLoader && <Loader />}
    </div>
  )
}
