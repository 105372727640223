import React, { useEffect, useState } from 'react'
import styles from './RecentProjectPage.module.css'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ProductPageHeader from '../ProductsHeader/ProductPageHeader'
import ProductsFooter from '../ProductsFooter/ProductsFooter'
import { TbCurrencyDram } from 'react-icons/tb'
import { Button, Modal } from 'antd'
import Slider from 'react-slick'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import Box from '@mui/material/Box'
import Loader from '../Loader/Loader'
import { Link } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import './table.css'

const useStyles = createUseStyles({
  sliderContainer: {
    '& .slick-slide': {
      height: '300px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    '& .slick-slide img': {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      objectPosition: '50% 50%',
    },
    '@media (min-width: 500px)': {
      '& .slick-slide': {
        height: '600px',
        width: '100%',
      },
    },
  },
})

export default function RecentProjectPage() {
  const classes = useStyles()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { pathname } = useLocation()
  const [recentProject, setRecentProject] = useState({})
  const [recentProjects, setRecentProjects] = useState([])
  const recentProjectId = pathname.split('/')[3]
  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState(true)
  const lang = useSelector((state) => state.lang.lang.toLowerCase())

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [pathname])

  useEffect(() => {
    setLoading(true)
    const fetchRecentProject = async () => {
      setRecentProject({})
      try {
        const { data } = await axios.get(
          `https://elwoodnelinka-4b15b25292df.herokuapp.com/recentProject/${recentProjectId}`
        )
        const langLowerCase = lang.toLowerCase()

        if (data) {
          const properties = data[`properties_${langLowerCase}`]
            ? JSON.parse(data[`properties_${langLowerCase}`])
            : []

          const designPartner = data[`designPartner_${langLowerCase}`]
            ? data[`designPartner_${langLowerCase}`]
            : ''
          const designPartnerLink = data[`designPartnerLink_${langLowerCase}`]
            ? data[`designPartnerLink_${langLowerCase}`]
            : ''

          const propertiesArray = Object.values(properties)

          setRecentProject({
            ...data,
            title: data[`title_${langLowerCase}`]
              ? data[`title_${langLowerCase}`]
              : '',
            description: data[`description_${langLowerCase}`]
              ? data[`description_${langLowerCase}`]
              : '',
            disabled: data?.disabled,
            status: data?.status,
            images: data[`images_${langLowerCase}`]
              ? JSON.parse(data[`images_${langLowerCase}`])
              : [],
            properties: propertiesArray,
            designPartner,
            designPartnerLink,
          })
        }

        setLoading(false)
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }
    fetchRecentProject()
  }, [recentProjectId, lang])

  useEffect(() => {
    const fetchRecentProjects = async () => {
      try {
        const { data } = await axios.get(
          'https://elwoodnelinka-4b15b25292df.herokuapp.com/recentProjects'
        )
        const langLowerCase = lang.toLowerCase()

        const filteredDatas =
          data &&
          data
            .filter((item) => +item.disabled === 0)
            .filter((item) => +item.id !== +recentProjectId)
            .map((item) => {
              const images = item[`images_${langLowerCase}`]
                ? JSON.parse(item[`images_${langLowerCase}`])
                : []

              const properties = item[`properties_${langLowerCase}`]
                ? JSON.parse(item[`properties_${langLowerCase}`])
                : []

              const title = item[`title_${langLowerCase}`]
                ? item[`title_${langLowerCase}`]
                : ''
              const designPartner = item[`designPartner_${langLowerCase}`]
                ? item[`designPartner_${langLowerCase}`]
                : ''
              const designPartnerLink = item[
                `designPartnerLink_${langLowerCase}`
              ]
                ? item[`designPartnerLink_${langLowerCase}`]
                : ''

              return {
                ...item,
                images,
                properties,
                title,
                designPartner,
                designPartnerLink,
              }
            })

        setRecentProjects(filteredDatas.slice(0, 4))
      } catch (error) {
        console.log(error)
      }
    }
    fetchRecentProjects()
  }, [recentProjectId, recentProject])

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    className: classes.sliderContainer,
  }

  return (
    <>
      <ProductPageHeader />
      <section className={styles.section}>
        <div className={styles.container}>
          <div className={styles.description}>{recentProject?.description}</div>

          <div className={styles.imgContainer}>
            <div style={{ textAlign: 'end', padding: 4 }}>
              <p className={styles.imageTopRightPiece}>
                {recentProject?.title}
              </p>
            </div>
            <div className={styles.imgWrapper}>
              <img
                className={styles.img}
                src={recentProject.images && recentProject.images[0]?.jpeg}
                alt={recentProject?.title}
              />
            </div>
            <div className={styles.optionsContainer}>
              <div className={styles.options}>
                <table>
                  <h1 className={styles.optionsTitle}>Հավելյալ Ինֆորմացիա</h1>
                  <tbody>
                    <tr>
                      <td className="leftTd">Դիզայն</td>
                      <td className="rightTd">
                        <a
                          target="_blank"
                          href={
                            recentProject?.designPartnerLink
                              ? recentProject?.designPartnerLink
                              : ''
                          }>
                          {' '}
                          {recentProject?.designPartner
                            ? recentProject?.designPartner
                            : ''}
                        </a>
                      </td>
                    </tr>
                    {recentProject.properties &&
                      Array.isArray(recentProject.properties) &&
                      recentProject.properties.map((item) => (
                        <tr>
                          <td className="leftTd">{item.title}</td>
                          <td className="rightTd">{item.description}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        style={{ display: 'flex', justifyContent: 'center', marginBlock: 100 }}>
        <Box sx={{ width: 650, height: 'auto' }}>
          <ImageList variant="masonry" cols={3} gap={16}>
            {recentProject.images &&
              recentProject.images.map((item) => (
                <ImageListItem variant="medium" key={item.img}>
                  <img
                    src={`${item.jpeg}?w=248&fit=crop&auto=format`}
                    srcSet={`${item.jpeg}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.title}
                    loading="lazy"
                    onClick={showModal}
                  />
                </ImageListItem>
              ))}
          </ImageList>
        </Box>
      </div>
      {/* <section className={styles.secondSection}>
        <div className={styles.containerHeader}>
          <header className={styles.header}>
            <div className={styles.titleSimilar}>{t('additionalProjects')}</div>
          </header>
        </div>
        <div className={styles.similarCards}>
          <div className={styles.cardContainer}>
            <div className={styles.productWrapper}>
              <div className={styles.productsContainer}>
                {recentProjects.length &&
                  recentProjects.map((product) => (
                    <div key={product.id} className={styles.product}>
                      <Link
                        onClick={() => window.scrollTo(0, 0)}
                        to={`/recentProjects/project/${product.id}`}
                        className={styles.linkContainerProduct}>
                        <div style={{ overflow: 'hidden' }}>
                          {product.images &&
                            product.images[0] &&
                            product.images[0].jpeg && (
                              <img
                                src={product.images[0].jpeg}
                                alt="Product Image"
                                className={styles.productImg}
                              />
                            )}
                        </div>
                      </Link>
                      <h2 className={styles.productname}>{product.title}</h2>
                      <p className={styles.productType}>{product.date}</p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}>
        <Slider {...settings}>
          {recentProject.images &&
            recentProject.images.map((item) => (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <img
                  src={item?.jpeg}
                  alt={item.title}
                  style={{
                    height: 'auto',
                    maxWidth: '100%',
                    verticalAlign: 'middle',
                    border: 0,
                  }}
                />
              </div>
            ))}
        </Slider>
      </Modal>
      <ProductsFooter />
      {loading && <Loader />}
    </>
  )
}
