import styles from './MainInformation.module.css'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'
import LoadingButton from '@mui/lab/LoadingButton'
import SendIcon from '@mui/icons-material/Send'

export default function MainInformation() {
  const [countClient, setClientsCount] = useState('')
  const [completedProject, setCompletedProjects] = useState('')
  const [open, setOpen] = useState(false)
  const [openCompletedProject, setOpenCompletedProject] = useState(false)
  const [countClientValue, setCountClientValue] = useState(countClient)
  const [completedProjectValue, setCompletedProjectsValue] =
    useState(completedProject)

  useEffect(() => {
    const fetchInformation = async () => {
      try {
        const { data } = await axios.get(
          'https://elwoodnelinka-4b15b25292df.herokuapp.com/information'
        )
        setClientsCount(data && data[0]?.clients)
        setCompletedProjects(data && data[0]?.completedProjects)
      } catch (error) {
        console.log(error)
      }
    }
    fetchInformation()
  }, [])

  const handleOpenClientsCount = () => {
    setOpen(true)
  }
  const handleOpenCompletedProjects = () => {
    setOpenCompletedProject(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
    setOpenCompletedProject(false)
    setCountClientValue('')
    setCompletedProjectsValue('')
  }

  const handleChangeClientCount = (e) => {
    setCountClientValue(e.target.value)
  }

  const handleChangeCompletedProjects = (e) => {
    setCompletedProjectsValue(e.target.value)
  }

  const handleSendClientCount = async () => {
    try {
      await axios
        .put('https://elwoodnelinka-4b15b25292df.herokuapp.com/edit/information', {
          client: countClientValue,
        })
        .then(() => {
          window.location.reload()
        })
    } catch (error) {
      console.log(error)
    }
  }
  const handleSendCompletedProjects = async () => {
    try {
      await axios
        .put('https://elwoodnelinka-4b15b25292df.herokuapp.com/edit/information', {
          completedProjects: completedProjectValue,
        })
        .then(() => {
          window.location.reload()
        })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <h2 className={styles.title}>Client count | Completed projects</h2>
        </div>

        <div className={styles.content}>
          <div className={styles.clientCountContainer}>
            <div className={styles.cc}>
              <label className={styles.label}>Clients Count:</label>
              <h2 className={styles.info}>{countClient}</h2>
            </div>
            <div
              className={`${styles.button} ${styles.button3}`}
              onClick={handleOpenClientsCount}>
              Edit
            </div>
          </div>
          <div className={styles.completedProjectCountContainer}>
            <div className={styles.cp}>
              <label className={styles.label}>Completed Projects:</label>
              <h2 className={styles.info}>{completedProject}</h2>
            </div>
            <div
              className={`${styles.button} ${styles.button3}`}
              onClick={handleOpenCompletedProjects}>
              Edit
            </div>
          </div>
        </div>
      </div>
      <Dialog open={open} onClose={handleCloseModal}>
        <DialogTitle>Edit Client Count</DialogTitle>
        <div style={{ padding: '16px' }}>
          <TextField
            required
            autoFocus
            margin="dense"
            value={countClientValue}
            onChange={handleChangeClientCount}
            name="clientCount"
            id="location"
            label="Enter Client Count"
            type="number"
            fullWidth
            variant="standard"
            style={{ marginBottom: '16px' }}
          />

          <Stack direction="row" spacing={2}>
            <LoadingButton onClick={handleCloseModal} variant="outlixaned">
              Cancel
            </LoadingButton>
            <LoadingButton
              onClick={handleSendClientCount}
              endIcon={<SendIcon />}
              //   loading={loadingEdit}
              variant="contained">
              <span>Edit</span>
            </LoadingButton>
          </Stack>
        </div>
      </Dialog>
      <Dialog open={openCompletedProject} onClose={handleCloseModal}>
        <DialogTitle>Edit Completed Projects</DialogTitle>
        <div style={{ padding: '16px' }}>
          <TextField
            required
            autoFocus
            margin="dense"
            value={completedProjectValue}
            onChange={handleChangeCompletedProjects}
            name="clientCount"
            id="location"
            label="Enter Client Count"
            type="number"
            fullWidth
            variant="standard"
            style={{ marginBottom: '16px' }}
          />

          <Stack direction="row" spacing={2}>
            <LoadingButton onClick={handleCloseModal} variant="outlixaned">
              Cancel
            </LoadingButton>
            <LoadingButton
              onClick={handleSendCompletedProjects}
              endIcon={<SendIcon />}
              //   loading={loadingEdit}
              variant="contained">
              <span>Edit</span>
            </LoadingButton>
          </Stack>
        </div>
      </Dialog>
    </div>
  )
}
