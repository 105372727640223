import styles from './AddAbout.module.css'
import { Editor } from '@tinymce/tinymce-react'
import { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import { AiOutlineCloudUpload, AiOutlineClose } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { getDownloadURL, listAll, ref, uploadBytes } from 'firebase/storage'
import { v4 as uuid } from 'uuid'
import storage from '../../../firebase'
import imageCompression from 'browser-image-compression'
import { BarLoader } from 'react-spinners'

export default function AddAboutRu() {
  const navigate = useNavigate()
  const [content, setContent] = useState('')
  const [files, setFiles] = useState(null)
  const [description, setDescription] = useState('')
  const [img, setImg] = useState(null)
  const [webpSupported, setWebpSupported] = useState(false)
  const [isLoadingImgs, setIsLoadingImgs] = useState(false)
  const [addLoading, setAddLoading] = useState(false)
  const [lang, setLang] = useState('rus')

  const onInputChange = (e) => {
    setFiles(e.target.files[0])
  }

  async function sendingClick(e) {
    e.preventDefault()
    setAddLoading(true)

    try {
      await axios
        .post('https://elwoodnelinka-4b15b25292df.herokuapp.com/addAbout', {
          description: description,
          img: JSON.stringify(img),
          lang: lang,
        })
        .then(() => {
          toast.success('Russian about has been created successfully !')
          setTimeout(() => {
            navigate('/admin/admindashboard/aboutUs')
          }, 3000)
        })
        .catch((err) => {
          setAddLoading(false)
          toast.error(err.message)
        })
    } catch (error) {
      console.log(error)
      setAddLoading(false)
    }
  }

  async function onSubmit() {
    if (files === null) return
    const newName = files.name + uuid()
    setIsLoadingImgs(true)
    try {
      const compressedImage = await imageCompression(files, {
        maxSize: 500 * 1024,
        maxWidthOrHeight: 800,
      })

      const jpegImageRef = ref(storage, `images/about/${newName}.jpeg`)
      await uploadBytes(jpegImageRef, compressedImage).then(() =>
        console.log('Jpeg has been uploaded success !')
      )

      const webpImage = await createImageBitmap(files)
      const webpCanvas = document.createElement('canvas')
      webpCanvas.width = webpImage.width
      webpCanvas.height = webpImage.height
      const webpCanvasContext = webpCanvas.getContext('2d')
      webpCanvasContext.drawImage(webpImage, 0, 0)
      webpCanvas.toBlob(
        async (webpBlob) => {
          if (webpBlob) {
            const webpImageRef = ref(storage, `images/about/${newName}.webp`)
            await uploadBytes(webpImageRef, webpBlob).then(() =>
              toast.success('Images has been uploaded success!')
            )
            setIsLoadingImgs(false)
            const jpegDownloadURL = await getDownloadURL(jpegImageRef)
            const webpDownloadURL = await getDownloadURL(webpImageRef)
            setImg({ jpeg: jpegDownloadURL, webp: webpDownloadURL })
          }
        },
        'image/webp',
        0.9
      )
    } catch (error) {
      setIsLoadingImgs(false)
      console.log(error)
    }
  }

  function handleSubmit(e) {
    e.preventDefault()
    onSubmit()
  }

  useEffect(() => {
    const container = document.createElement('p')

    container.innerHTML = content

    const descriptionElement = container.querySelector('p')
    const descriptionRu = descriptionElement && descriptionElement.outerHTML

    setDescription(descriptionRu)
  }, [content])

  const handleEditorChange = (content, editor) => {
    setContent(content)
  }

  const handleFilePicker = (callback, value, meta) => {
    if (meta.filetype === 'image') {
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/jpeg')
      input.onchange = function () {
        const file = this.files[0]
        callback(`/image/main/${file.name}`)
      }
      input.click()
    }
  }

  useEffect(() => {
    const canvas = document.createElement('canvas')
    if (canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0) {
      setWebpSupported(true)
    } else {
      setWebpSupported(false)
    }
  }, [])

  return (
    <div>
      <h1 className={styles.title}>Русский вариант</h1>
      <Editor
        className={styles.editor}
        apiKey="your_api_key"
        initialValue="<p>Добавить русский вариант для о нас стрницы </p>"
        init={{
          height: 500,
          plugins: 'link image code',
          toolbar:
            'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | link image | code',
          automatic_uploads: true,
          file_picker_types: 'image',
          file_picker_callback: handleFilePicker,
        }}
        onEditorChange={handleEditorChange}
      />

      <div className={styles.imgContainerWrapper}>
        {' '}
        <div className={styles.container}>
          <input
            type="file"
            id="file"
            accept="image/*"
            hidden
            onChange={onInputChange}
          />
          <div
            className={`${styles.imgArea} ${img ? styles.active : ''}`}
            data-img="">
            {img ? (
              <div>
                <img
                  src={webpSupported ? img?.webp : img?.jpeg}
                  alt="Selected"
                />
                <AiOutlineClose className={styles.deleteImg} />
              </div>
            ) : (
              <>
                <AiOutlineCloudUpload />
                <h3>Upload Image</h3>
                <p>
                  Image size must be less than <span>2MB</span>
                </p>
              </>
            )}
          </div>
          {isLoadingImgs && (
            <BarLoader width="100%" color="rgb(219, 128, 48)" />
          )}
          <div className={styles.sendBtnContainer}>
            <Stack spacing={2} direction="row">
              <Button onClick={handleSubmit} variant="outlined">
                Send Img
              </Button>
            </Stack>
          </div>
          <button
            className={styles.selectImage}
            onClick={() => document.querySelector('#file').click()}>
            Select Image
          </button>
        </div>
        <div className={styles.sendBtnContainer}>
          <Stack direction="row" spacing={2}>
            <Button
              type="button"
              onClick={sendingClick}
              variant="contained"
              disabled={addLoading}>
              Добавить
            </Button>
          </Stack>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}
