import styles from './FilterPanel.module.css'
import { FaSearch } from 'react-icons/fa'
import { useEffect, useState } from 'react'
import axios from 'axios'
import Box from '@mui/material/Box'
import Slider from '@mui/material/Slider'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import debounce from 'lodash.debounce'
import { Link } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import ReactPaginate from 'react-paginate'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import { TbSearchOff } from 'react-icons/tb'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Button from '@mui/material/Button'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import Stack from '@mui/material/Stack'
import { useTranslation } from 'react-i18next'
import { TbCurrencyDram } from 'react-icons/tb'
import { useSelector } from 'react-redux'
import ProductCard from '../../../components/ProductCard/ProductCard'

AOS.init()

const minDistance = 100

export default function FilterPanel() {
  const [webpSupported, setWebpSupported] = useState(false)
  const [min, setMin] = useState(0)
  const [max, setMax] = useState(2000000)
  const [products, setProducts] = useState([])
  const [colors, setColors] = useState([])
  const [categories, setCategories] = useState([
    {
      category: 'All',
    },
  ])
  const [search, setSearch] = useState('')
  const [category, setCategory] = useState('All')
  const [price, setPrice] = useState([min, max])
  const [color, setColor] = useState('')
  const [materials, setMaterials] = useState([
    {
      material: 'All',
    },
  ])
  const [material, setMaterial] = useState('All')
  const [width, setWidth] = useState('')
  const [pageCount, setPageCount] = useState(0)
  const [currentItems, setCurrentItems] = useState(0)
  const [filtered, setFiltered] = useState([])
  const [itemOffset, setItemOffset] = useState(0)
  const itemsPerPage = 25
  const lang = useSelector((state) => state.lang.lang)
  const { t, i18n } = useTranslation()

  console.log('CURRENT ITEMS')
  console.log(currentItems)

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage
    setCurrentItems(products.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(products.length / itemsPerPage))
  }, [itemOffset, itemsPerPage, products])

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % products.length
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    )
    setItemOffset(newOffset)
  }

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    document.title = `${t('products')} / Elwood`
  }, [lang])

  useEffect(() => {
    document.title = `${t('products')} / Elwood`

    const metaTagTitle = document.querySelector('meta[property="og:title"]')
    metaTagTitle.setAttribute('content', `${t('products')} / Elwood`)
    const mataImage = document.querySelector('meta[property="og:image"]')
    mataImage.setAttribute('content', '/image/site.jpeg')
    const metaDescription = document.querySelector(
      'meta[property="og:description"]'
    )
    metaDescription.setAttribute(
      'content',
      'DESCRIPTION Elwood.am - Կահույքի լավագույն օնլայն խանութը Հայաստանում DESCRIPTION'
    )

    return () => {
      metaTagTitle.setAttribute('content', `${t('products')} / Elwood`)
      mataImage.setAttribute('content', '/image/site.jpeg')
      metaDescription.setAttribute(
        'content',
        'DESCRIPTION Elwood.am - Կահույքի լավագույն օնլայն խանութը Հայաստանում DESCRIPTION'
      )
    }
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [currentItems])

  const handleChangeColor = (itemColor) => {
    setColor(color !== itemColor ? itemColor : '')
  }

  function handleInputChange(e) {
    setSearch(e.target.value)
  }

  function handleRangeChange(e, newValue, activeThumb) {
    if (!Array.isArray(newValue)) {
      return
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], price[1] - minDistance)
        setPrice([clamped, clamped + minDistance])
      } else {
        const clamped = Math.max(newValue[1], price[0] + minDistance)
        setPrice([clamped - minDistance, clamped])
      }
    } else {
      setPrice(newValue)
    }
  }

  function handleResetFilter() {
    setCategory('All')
    setSearch('')
    setPrice([min, max])
    window.location.reload()
  }

  useEffect(() => {
    const fetchColors = async () => {
      try {
        const res = await axios.get(
          'https://elwoodnelinka-4b15b25292df.herokuapp.com/colors'
        )
        const parsetData = res.data.map((item) => ({
          color: item.color,
          id: item.id,
          rgb: JSON.parse(item.rgb),
          disabled: item.disabled,
        }))
        const uniqueColors = new Set(parsetData.map((item) => item.color))
        const uniqueData = Array.from(uniqueColors)
          .map((color) => parsetData.find((item) => item.color === color))
          .filter((item) => !item.disabled)
        setColors(uniqueData)
      } catch (error) {
        console.log(error)
      }
    }
    fetchColors()
  }, [])

  const fetchingPrices = debounce(async () => {
    try {
      const langLowCase = lang.toLowerCase()
      const res = await axios.get(
        'https://elwoodnelinka-4b15b25292df.herokuapp.com/products'
      )

      console.log('fetch pricing fn res')
      console.log(res)
      console.log(price)

      const filteredDatas =
        res.data &&
        res.data
          .filter(
            (product) =>
              product[`price_${langLowCase}`] >= price[0] &&
              product[`price_${langLowCase}`] <= price[1]
          )
          .map((item) => {
            console.log('filter datas .map item')
            console.log(item)

            const imgs = item[`imgs_${langLowCase}`]
              ? JSON.parse(item[`imgs_${langLowCase}`])
              : []
            const colors = item[`colors_${langLowCase}`]
              ? JSON.parse(item[`colors_${langLowCase}`])
              : []
            const categories = item[`category_${langLowCase}`]
              ? JSON.parse(item[`category_${langLowCase}`])
              : []
            const material = item[`material_${langLowCase}`]
              ? JSON.parse(item[`material_${langLowCase}`])
              : []

            const categoryItem = categories.filter(
              (item) => item.lang === langLowCase
            )

            const categoryValue =
              categoryItem.length > 0 ? categoryItem[0].category : null

            return {
              ...item,
              [`imgs_${langLowCase}`]: imgs,
              [`colors_${langLowCase}`]: colors,
              [`category_${langLowCase}`]: categoryValue,
              [`material_${langLowCase}`]: material,
              allCategories: categories,
            }
          })
          .filter((item) => !item.disabled)

      console.log('FILTERED DATAS')
      console.log(filteredDatas)

      setProducts(filteredDatas)
    } catch (error) {
      console.log(error)
    }
  }, 1000)

  console.log('products')
  console.log(products)

  // useEffect(() => {
  //   setProducts((prevProducts) =>
  //     prevProducts.map((product) => {
  //       const updatedCategories = product.allCategories
  //         .map((category) => {
  //           if (category.lang === lang.toLowerCase()) {
  //             return category
  //           }
  //           return null
  //         })
  //         .filter((category) => category !== null)

  //       return {
  //         ...product,
  //         category:
  //           updatedCategories.length > 0 ? updatedCategories[0].category : null,
  //       }
  //     })
  //   )
  // }, [lang])

  useEffect(() => {
    fetchingPrices()
    return () => fetchingPrices.cancel()
  }, [price])

  const fetchProducts = async () => {
    try {
      const langLowCase = lang.toLowerCase()
      const res = await axios.get(
        'https://elwoodnelinka-4b15b25292df.herokuapp.com/products'
      )

      const productsWithParsedJSON = res.data
        .map((item) => {
          const imgs = item[`imgs_${langLowCase}`]
            ? JSON.parse(item[`imgs_${langLowCase}`])
            : []
          const colors = item[`colors_${langLowCase}`]
            ? JSON.parse(item[`colors_${langLowCase}`])
            : []
          const categories = item[`category_${langLowCase}`]
            ? JSON.parse(item[`category_${langLowCase}`])
            : []
          const material = item[`material_${langLowCase}`]
            ? JSON.parse(item[`material_${langLowCase}`])
            : []

          const categoryItem = categories.filter(
            (item) => item.lang === langLowCase
          )

          const categoryValue =
            categoryItem.length > 0 ? categoryItem[0].category : null

          return {
            ...item,
            allCategories: categories,
            [`imgs_${langLowCase}`]: imgs,
            [`colors_${langLowCase}`]: colors,
            [`category_${langLowCase}`]: categoryValue,
            [`material_${langLowCase}`]: material,
          }
        })
        .filter((item) => !item.disabled)

      const prices = productsWithParsedJSON.map(
        (item) => item[`price_${langLowCase}`]
      )
      const maxPrice = Math.max(...prices)
      const minPrice = Math.min(...prices)
      setMin(minPrice)
      setMax(maxPrice)
      setProducts(productsWithParsedJSON)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchProducts()
  }, [])

  useEffect(() => {
    fetchProducts()
  }, [lang])

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await axios.get(
          'https://elwoodnelinka-4b15b25292df.herokuapp.com/categories'
        )
        const filteredCategories =
          res.data &&
          res.data
            .filter((item) => !item.disabled)
            .filter((item) => item.lang === lang.toLowerCase())
        setCategories(filteredCategories)
      } catch (error) {
        console.log(error)
      }
    }
    fetchCategories()
  }, [])

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await axios.get(
          'https://elwoodnelinka-4b15b25292df.herokuapp.com/categories'
        )
        const filteredCategories =
          res.data &&
          res.data
            .filter((item) => !item.disabled)
            .filter((item) => item.lang === lang.toLowerCase())
        setCategories(filteredCategories)
      } catch (error) {
        console.log(error)
      }
    }
    fetchCategories()
  }, [lang])

  useEffect(() => {
    const fetchMaterials = async () => {
      try {
        const res = await axios.get(
          'https://elwoodnelinka-4b15b25292df.herokuapp.com/materials'
        )
        const filteredMaterials =
          res.data &&
          res.data
            .filter((item) => !item.disabled)
            .filter((item) => item.lang === lang.toLowerCase())

        setMaterials(filteredMaterials)
      } catch (error) {
        console.log(error)
      }
    }
    fetchMaterials()
  }, [])

  useEffect(() => {
    const fetchMaterials = async () => {
      try {
        const res = await axios.get(
          'https://elwoodnelinka-4b15b25292df.herokuapp.com/materials'
        )
        const filteredMaterials =
          res.data &&
          res.data
            .filter((item) => !item.disabled)
            .filter((item) => item.lang === lang.toLowerCase())

        setMaterials(filteredMaterials)
      } catch (error) {
        console.log(error)
      }
    }
    fetchMaterials()
  }, [lang])

  function handleChange(e) {
    setCategory(e.target.value)
  }

  function handleChangeMaterial(e) {
    setMaterial(e.target.value)
  }

  useEffect(() => {
    const canvas = document.createElement('canvas')
    if (canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0) {
      setWebpSupported(true)
    } else {
      setWebpSupported(false)
    }
  }, [])

  return (
    <>
      <section
        viewport={{ amount: 0.2 }}
        initial="hidden"
        whileInView="visible"
        className={styles.section}>
        <div className={`${styles.container} ${styles.stretch}`}>
          <div className={styles.left}>
            <div className={styles.filterPanel} data-aos="fade-right">
              <div className={styles.filterForm}>
                <div className={styles.searchBar} data-aos="fade-up">
                  <input
                    type="text"
                    placeholder={`${t('search')}...`}
                    value={search}
                    onChange={handleInputChange}
                    className={styles.inpSearch}
                  />
                  <FaSearch className={styles.searchIcon} />
                </div>

                <label className={styles.filterLabel} data-aos="fade-up">
                  {t('price')}
                </label>
                <div className={styles.rangeContainer} data-aos="fade-up">
                  <div className={styles.filterPriceRange}>
                    <Box
                      sx={{
                        width: width > 420 ? 350 : 200,
                        display: 'flex',
                        justifyContent: 'center',
                      }}>
                      <Slider
                        sx={{ color: 'rgb(219, 128, 48)' }}
                        getAriaLabel={() => 'Price range'}
                        value={price}
                        onChange={handleRangeChange}
                        valueLabelDisplay="auto"
                        disableSwap
                        min={min && min}
                        max={max && max}
                      />
                    </Box>
                  </div>
                </div>
              </div>
              <div className={styles.filterGroup}>
                <div className={styles.filterGroupHeader}>{t('category')}:</div>
                <div className={styles.filterGroupContent}>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={category}
                        onChange={handleChange}>
                        <MenuItem value="All">{t('all')}</MenuItem>
                        {categories.map((category) => (
                          <MenuItem key={uuid()} value={category.category}>
                            {category.category}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </div>
              </div>

              <div className={styles.filterGroup}>
                <div className={styles.filterGroupHeader}>{t('material')}:</div>
                <div className={styles.filterGroupContent}>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={material}
                        onChange={handleChangeMaterial}>
                        <MenuItem value="All">{t('all')}</MenuItem>
                        {materials.map((material) => (
                          <MenuItem key={uuid()} value={material.material}>
                            {material.material}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </div>
              </div>
              <div className={styles.filterGroup}>
                <div className={styles.filterGroupHeader}>{t('color')}:</div>
                <div className={styles.filterGroupContent}>
                  <div className={styles.colorRowContainer}>
                    {colors &&
                      colors.map((itemColor, index) => (
                        <div
                          data-aos="fade-left"
                          key={index}
                          className={styles.colorContainer}>
                          <div
                            onClick={() => handleChangeColor(itemColor.color)}
                            style={{
                              backgroundColor: `rgb(${itemColor.rgb.map(
                                (item) => item
                              )})`,
                              border:
                                itemColor.color === '#ffffff' &&
                                '1px solid #ccc',
                            }}
                            className={`${styles.color} ${
                              color === itemColor.color && styles.activeColor
                            }`}></div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className={styles.filterButtonContainer}>
                <Stack
                  sx={{ color: 'rgb(219, 128, 48)' }}
                  direction="row"
                  spacing={2}>
                  <Button
                    endIcon={<RotateLeftIcon />}
                    onClick={handleResetFilter}
                    variant="contained"
                    sx={{
                      color: '#fff',
                      backgroundColor: 'rgb(219, 128, 48)',
                      '&:hover': {
                        backgroundColor: 'rgb(200, 100, 0)',
                      },
                    }}>
                    {t('reset')}
                  </Button>
                </Stack>
              </div>
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.productsContainer}>
              {currentItems &&
              Array.isArray(currentItems) &&
              currentItems
                .filter((product) =>
                  search === ''
                    ? true
                    : product[`name_${lang.toLowerCase()}`]
                        .toLowerCase()
                        .includes(search.toLowerCase())
                )
                .filter((product) =>
                  category === 'All'
                    ? true
                    : product[`category_${lang.toLowerCase()}`] === category
                )
                .filter((product) =>
                  material === 'All'
                    ? true
                    : product[`material${lang.toLowerCase()}`].includes(
                        material
                      )
                )
                .filter((product) =>
                  color === ''
                    ? true
                    : product[`colors_${lang.toLowerCase()}`].includes(color)
                ).length > 0 ? (
                currentItems
                  .filter((product) =>
                    search === ''
                      ? true
                      : product[`name_${lang.toLowerCase()}`]
                          .toLowerCase()
                          .includes(search.toLowerCase())
                  )
                  .filter((product) =>
                    category === 'All'
                      ? true
                      : product[`category_${lang.toLowerCase()}`] === category
                  )
                  .filter((product) =>
                    material === 'All'
                      ? true
                      : product[`material_${lang.toLowerCase()}`].includes(
                          material
                        )
                  )
                  .filter((product) =>
                    color === ''
                      ? true
                      : product[`colors_${lang.toLowerCase()}`].includes(color)
                  )
                  .map((product, index) => (
                    <ProductCard
                      item={product}
                      index={index}
                      filterPanel={true}
                    />
                  ))
              ) : (
                <div className={styles.cmp}>
                  <div className={styles.containerNotFound}>
                    <p className={styles.nothingFound}>Nothing found</p>
                    <span className={styles.ico}>
                      <TbSearchOff className={styles.iconNotFound} />
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.paginationContainer}>
          <ReactPaginate
            breakLabel="..."
            nextLabel={<MdKeyboardArrowRight className={styles.nextLabel} />}
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel={
              <MdKeyboardArrowLeft className={styles.previousLabel} />
            }
            renderOnZeroPageCount={null}
            containerClassName={styles.pagination}
            pageLinkClassName={styles.pageNum}
            activeLinkClassName={styles.activePage}
            marginPagesDisplayed={1}
          />
        </div>
      </section>
    </>
  )
}
