import styles from './AdminPartners.module.css'
import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import axios from 'axios'
import Stack from '@mui/material/Stack'
import LoadingButton from '@mui/lab/LoadingButton'
import SendIcon from '@mui/icons-material/Send'
import { MdOutlineTitle, MdOutlineDescription } from 'react-icons/md'
import { toast, ToastContainer } from 'react-toastify'
import {
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  deleteObject,
} from 'firebase/storage'
import { AiOutlineClose, AiOutlineCloudUpload } from 'react-icons/ai'
import storage from '../../firebase'
import { v4 as uuid } from 'uuid'
import Loader from '../../components/Loader/Loader'
import { Modal as AntDModal } from 'antd'

export default function AdminInfoAccordeon() {
  const [loading, setLoading] = useState(false)
  const [loaderLoading, setLoaderLoading] = useState(false)
  const [loadingEdit, setLoadingEdit] = useState(false)
  const [lang, setLang] = useState('ENG')
  const [open, setOpen] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [itemId, setItemId] = useState(null)
  const [infoId, setInfoId] = useState(null)
  const [image, setImage] = useState(null)
  const [imageLink, setImgLink] = useState('')

  const showModal = () => {
    setDeleteOpen(true)
  }

  const hideModal = () => {
    setDeleteOpen(false)
  }

  const [editValues, setEditValues] = useState({
    title: '',
    description: '',
    no: 0,
  })
  const [values, setValues] = useState({
    title: '',
    description: '',
    no: 0,
  })

  function onInputChange(e) {
    setImage(e.target.files[0])
  }

  async function handleClickOpenEdit(item) {
    setOpenEdit(true)
    setInfoId(item.id)
    setEditValues({
      title: item.title,
      description: item.description,
      no: item.no,
      lang: item.lang,
    })
  }

  function handleCloseEdit() {
    setOpenEdit(false)
    setEditValues({ title: '', description: '', no: 0 })
  }

  useEffect(() => {
    if (lang === 'ENG') {
      setValues((prev) => ({ ...prev, no: 1 }))
    } else if (lang === 'RUS') {
      setValues((prev) => ({ ...prev, no: 2 }))
    } else if (lang === 'ARM') {
      setValues((prev) => ({ ...prev, no: 3 }))
    }
  }, [lang])

  const handleClickOpen = () => {
    setOpen(true)
  }

  function handleChangeValues(e) {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }
  function handleChangeValuesEdit(e) {
    setEditValues((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const handleClose = () => {
    setOpen(false)
  }

  function handleChange(e) {
    setLang(e.target.value)
  }

  useEffect(() => {
    fetchImgLinks()
  }, [values.no])

  const fetchImgLinks = async () => {
    setLoaderLoading(true)
    try {
      const res = await axios.get(
        'https://elwoodnelinka-4b15b25292df.herokuapp.com/partners'
      )
      if (res.data) {
        const imageListRef = ref(storage, 'images/partners')
        const images = []
        const listResult = await listAll(imageListRef)
        const imgLinks = await Promise.all(
          res.data &&
            res.data.map(async (partner) => {
              const imageRef = listResult.items.find(
                (itemRef) => itemRef.name === partner?.img
              )
              if (imageRef) {
                const imageUrl = await getDownloadURL(imageRef)
                return { ...partner, url: imageUrl }
              } else {
                return partner
              }
            })
        )
        const partnersDatas =
          imgLinks &&
          imgLinks.filter(
            (item) => item.lang === lang.toLowerCase() && +item.no === values.no
          )
        setImgLink(partnersDatas)
        setLoaderLoading(false)
      }
    } catch (error) {
      console.log(error)
      setLoaderLoading(false)
    }
  }

  useEffect(() => {
    fetchImgLinks()
  }, [])

  async function sendingValues() {
    let newName
    if (image !== null) {
      newName = image.name + uuid()
      const imageRef = ref(storage, `images/partners/${newName}`)

      await uploadBytes(imageRef, image).then(() => {
        console.log('img uploaded successfully')
      })
    }
    setLoading(true)
    try {
      await axios
        .post('https://elwoodnelinka-4b15b25292df.herokuapp.com/addPartner', {
          ...values,
          lang: lang.toLowerCase(),
          img: newName,
        })
        .then(() => {
          toast.success('Partner has been created successfully !')
          setValues({ title: '', description: '', no: 0 })
          setImage(null)
          setTimeout(() => {
            window.location.reload()
          }, 1200)
          setLoading(false)
        })
      setOpen(false)
    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    }
    setLoading(false)
  }

  async function handleDelete(info) {
    const imageRefJpeg = ref(storage, info.img)

    deleteObject(imageRefJpeg).then(() => console.log('Image has bee delete'))

    try {
      await axios.delete(
        `https://elwoodnelinka-4b15b25292df.herokuapp.com/deletePartner/${info.itemId}`
      )
      await fetchImgLinks()
    } catch (error) {
      toast.error(error.message)
    }
  }
  async function sendValuesEdit(id) {
    let newName
    if (image !== null) {
      newName = image.name + uuid()
      const imageRef = ref(storage, `images/partners/${newName}`)

      uploadBytes(imageRef, image).then(() => {
        toast.success('Partner img has been edit successfully!')
      })
    }

    try {
      await axios
        .put(`https://elwoodnelinka-4b15b25292df.herokuapp.com/editPartner/${id}`, {
          ...editValues,
          img: image && newName,
        })
        .then(() => {
          setLoadingEdit(false)
          setOpenEdit(false)
          setEditValues({
            title: '',
            description: '',
            no: 0,
          })
          setImage(null)
          fetchImgLinks()
          toast.success('Partner has been edit successfully !')
          window.location.reload()
        })
    } catch (error) {
      console.log(error)
      toast.error(error.message)
    }
  }

  return (
    <div className={styles.wrapper}>
      <h2 style={{ marginBottom: '100px' }}>Home Page Section 7</h2>

      <div className={styles.actions}>
        <div className={styles.languageContainer}>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Test</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={lang}
                label="Age"
                onChange={handleChange}>
                <MenuItem value="ENG">ENG</MenuItem>
                <MenuItem value={'ARM'}>ARM</MenuItem>
                <MenuItem value="RUS">RUS</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>
      </div>

      <div className={styles.container}>
        {imageLink &&
          imageLink.map((item) => (
            <div className={styles.content}>
              <div className={styles.avatarContainer}>
                <img
                  src={item?.url}
                  alt="Аватарка"
                  className={styles.avatarImg}
                />
              </div>
              <div className={styles.cont}>
                <div className={styles.label}>
                  {' '}
                  <span>
                    <MdOutlineTitle className={styles.phoneIcon} />{' '}
                  </span>
                  Title :
                </div>
                <h4 className={styles.title}>{item?.title}</h4>
              </div>
              <div className={styles.cont}>
                <div className={styles.label}>
                  {' '}
                  <span>
                    <MdOutlineDescription className={styles.phoneIcon} />
                  </span>
                  Description :
                </div>
                <h4 className={styles.desc}>{item?.description}</h4>
              </div>
              <div className={styles.btns}>
                <div
                  onClick={() => {
                    setItemId({ itemId: item?.id, img: item?.url })
                    showModal()
                  }}
                  className={`${styles.button} ${styles.button2}`}>
                  Delete
                </div>
                <div
                  onClick={() => handleClickOpenEdit(item)}
                  className={`${styles.button} ${styles.button3}`}>
                  Edit
                </div>
              </div>
            </div>
          ))}
        {/* {imageLink[1] && (
          <div className={styles.content}>
            <div className={styles.avatarContainer}>
              <img
                src={imageLink[1]?.url}
                alt="Аватарка"
                className={styles.avatarImg}
              />
            </div>
            <div className={styles.cont}>
              <div className={styles.label}>
                {' '}
                <span>
                  <ImLocation className={styles.phoneIcon} />{' '}
                </span>
                Title :
              </div>
              <h4 className={styles.title}>{imageLink[1]?.title}</h4>
            </div>
            <div className={styles.cont}>
              <div className={styles.label}>
                {' '}
                <span>
                  <GiModernCity className={styles.phoneIcon} />
                </span>
                Description :
              </div>
              <h4 className={styles.desc}>{imageLink[1]?.description}</h4>
            </div>
            <div className={styles.btns}>
              <div
                onClick={() => handleDelete(imageLink[1]?.id)}
                className={`${styles.button} ${styles.button2}`}>
                Delete
              </div>
              <div
                onClick={() => handleClickOpenEdit(imageLink[1])}
                className={`${styles.button} ${styles.button3}`}>
                Edit
              </div>
            </div>
          </div>
        )}
        {imageLink[2] && (
          <div className={styles.content}>
            <div className={styles.avatarContainer}>
              <img
                src={imageLink[2]?.url}
                alt="Partner img"
                className={styles.avatarImg}
              />
            </div>
            <div className={styles.cont}>
              <div className={styles.label}>
                {' '}
                <span>
                  <ImLocation className={styles.phoneIcon} />{' '}
                </span>
                Title :
              </div>
              <h4 className={styles.title}>{imageLink[2]?.title}</h4>
            </div>
            <div className={styles.cont}>
              <div className={styles.label}>
                {' '}
                <span>
                  <GiModernCity className={styles.phoneIcon} />
                </span>
                Description :
              </div>
              <h4 className={styles.desc}>{imageLink[2]?.description}</h4>
            </div>
            <div className={styles.btns}>
              <div
                onClick={() => handleDelete(imageLink[2]?.id)}
                className={`${styles.button} ${styles.button2}`}>
                Delete
              </div>
              <div
                onClick={() => handleClickOpenEdit(imageLink[2])}
                className={`${styles.button} ${styles.button3}`}>
                Edit
              </div>
            </div>
          </div>
        )} */}
        {/* {imageLink.length < 3 && ( */}
        <div onClick={handleClickOpen} className={styles.addBtnContainer}>
          <div className={`${styles.button} ${styles.button4}`}>
            Add partner with {lang}
          </div>
        </div>
        {/* )} */}

        <Dialog open={open} onClose={handleClose}>
          <Box sx={{ minWidth: '500px', height: '600px' }}>
            <DialogTitle>Add {lang} variant</DialogTitle>
            <div style={{ padding: '16px' }}>
              <TextField
                required
                autoFocus
                margin="dense"
                value={values.title}
                onChange={handleChangeValues}
                name="title"
                id="title"
                label="Title"
                type="text"
                fullWidth
                variant="standard"
                style={{ marginBottom: '16px' }}
              />
              <TextField
                required
                autoFocus
                value={values.description}
                onChange={handleChangeValues}
                name="description"
                margin="dense"
                id="description"
                label="Description"
                type="email"
                fullWidth
                variant="standard"
                style={{ marginBottom: '60px' }}
              />
            </div>
            <div className={styles.mainImgContainer}>
              <div className={styles.uploadImgContainer}>
                <input
                  type="file"
                  id="file"
                  accept="image/*"
                  hidden
                  onChange={onInputChange}
                />
                <div
                  className={`${styles.imgArea} ${image ? styles.active : ''}`}
                  data-img="">
                  {imageLink.url ? (
                    <div>
                      <img
                        src={imageLink.url}
                        alt="Selected"
                        className={styles.img}
                      />
                      <AiOutlineClose className={styles.deleteImg} />
                    </div>
                  ) : (
                    <>
                      <AiOutlineCloudUpload />
                      <h3>Upload Image</h3>
                      <p>
                        Image size must be less than <span>2MB</span>
                      </p>
                    </>
                  )}
                </div>
                <div className={styles.imgButtonContainer}>
                  <button
                    className={styles.selectImage}
                    onClick={() => document.querySelector('#file').click()}>
                    Select Image
                  </button>
                </div>
              </div>
            </div>
            <div className={styles.sendAddContainerButton}>
              <Stack direction="row" spacing={2}>
                <LoadingButton onClick={handleClose} variant="outlixaned">
                  Cancel
                </LoadingButton>
                <LoadingButton
                  onClick={sendingValues}
                  disabled={!values.title || (!values.description && true)}
                  endIcon={<SendIcon />}
                  loading={loading}
                  variant="contained">
                  <span>Send</span>
                </LoadingButton>
              </Stack>
            </div>
          </Box>
        </Dialog>
        <Dialog open={openEdit} onClose={handleCloseEdit}>
          <DialogTitle>Edit {lang} variant</DialogTitle>
          <div style={{ padding: '16px' }}>
            <TextField
              required
              autoFocus
              margin="dense"
              value={editValues.title}
              onChange={handleChangeValuesEdit}
              name="title"
              id="title"
              label="Title"
              type="text"
              fullWidth
              variant="standard"
              style={{ marginBottom: '16px' }}
            />
            <TextField
              required
              autoFocus
              value={editValues.description}
              onChange={handleChangeValuesEdit}
              name="description"
              margin="dense"
              id="description"
              label="Description"
              type="email"
              fullWidth
              variant="standard"
              style={{ marginBottom: '60px' }}
            />
            <div className={styles.mainImgContainer}>
              <div className={styles.uploadImgContainer}>
                <input
                  type="file"
                  id="file"
                  accept="image/*"
                  hidden
                  onChange={onInputChange}
                />
                <div
                  className={`${styles.imgArea} ${image ? styles.active : ''}`}
                  data-img="">
                  {imageLink.url ? (
                    <div>
                      <img
                        src={imageLink.url}
                        alt="Selected"
                        className={styles.img}
                      />
                      <AiOutlineClose className={styles.deleteImg} />
                    </div>
                  ) : (
                    <>
                      <AiOutlineCloudUpload />
                      <h3>Upload Image</h3>
                      <p>
                        Image size must be less than <span>2MB</span>
                      </p>
                    </>
                  )}
                </div>
                <div className={styles.imgButtonContainer}>
                  <button
                    className={styles.selectImage}
                    onClick={() => document.querySelector('#file').click()}>
                    Select Image
                  </button>
                </div>
              </div>
            </div>
            <div className={styles.sendAddContainerButton}>
              <Stack direction="row" spacing={2}>
                <LoadingButton onClick={handleCloseEdit} variant="outlixaned">
                  Cancel
                </LoadingButton>
                <LoadingButton
                  onClick={() => sendValuesEdit(infoId)}
                  endIcon={<SendIcon />}
                  loading={loadingEdit}
                  variant="contained">
                  <span>Edit</span>
                </LoadingButton>
              </Stack>
            </div>
          </div>
        </Dialog>
      </div>
      <AntDModal
        title="You are sure delete category"
        open={deleteOpen}
        onOk={() => {
          handleDelete(itemId)
          hideModal()
        }}
        onCancel={hideModal}
        okText="Delete"
        cancelText="Cancel"></AntDModal>
      <ToastContainer />
      {loaderLoading && <Loader />}
    </div>
  )
}
